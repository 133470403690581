/**
 * 'Succeeded's Styled Components
 *
 * @flow
 */
import styled from 'styled-components';
import { breakpoints, colors, fonts } from '../../../Constants/css';

export const Content = styled.div`
  display: flex;

  flex: 1;
  flex-basis: auto; //Fix IE11

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
  }
`;

export const AdditionalBox = styled.div`
  margin-top: 24px;
  @media (min-width: ${breakpoints.tablet}) {
    margin: 32px 0 0 0;
  }
`;

export const Margin = styled.div`
  margin-bottom: 30px;
`;

export const Wrapper = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;
`;

export const Image = styled.img`
  margin: 24px 0 0 0;
`;

export const Topic = styled.div`
  margin: 24px 0 0 0;

  font-family: ${fonts.main};
  font-size: 18px;
  font-weight: bold;
  text-align: center;

  color: ${colors.common.black};

  @media (min-width: ${breakpoints.tablet}) {
    font-size: 20px;
  }
`;

export const Message = styled.div`
  margin: 8px 0 0 0;

  font-family: ${fonts.alternate};
  font-size: 15px;
  line-height: 22px;
  text-align: center;

  color: #000000;

  white-space: pre-line;

  @media (min-width: ${breakpoints.tablet}) {
    margin: 12px 0 0 0;
  }
`;
