/**
 * 'Select's Styled Components
 *
 * @flow
 */
import styled, { css } from 'styled-components';
import { colors, fonts } from '../../../Constants/css';

const { text, border } = colors;

// TODO: Использовать константы
const arrow__rotated = css`
  background: url(/assets/images/bg-opener.png) no-repeat 50% 50%;
  transform: rotate(180deg);
`;

const disabled = css`
  background-color: #f9fafb;
  cursor: not-allowed;
`;

const label = {
  active: css`
    font-size: 12px;
    white-space: normal;
    top: 8px;
  `,
};

export const Arrow = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  width: 36px;
  height: 100%;

  background: url(/assets/images/bg-opener.png) no-repeat 50% 50%;

  text-align: center;

  ${(p) => p.isOpen && arrow__rotated};
`;

export const SelectedValue = styled.span`
  display: inline-block;
  align-content: center;

  width: 100%;
  height: 100%;
  padding-right: 36px;
  padding-left: 12px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 22px;

  color: ${(props) => (props.disabled ? text.disabled : colors.common.black)};

  ${(props) =>
    props.hasLabel &&
    `
    padding-top: 28px;
    padding-bottom: 8px;
  `}
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const Label = styled.div`
  position: absolute;
  max-width: 101%;
  top: 19px;
  padding-left: 12px;
  padding-right: 36px;

  font-family: ${fonts.alternate};
  color: ${colors.common.grey1};
  white-space: nowrap;
  text-overflow: ellipsis;

  overflow: hidden;
  z-index: 1;

  ${(p) => p.selected && label.active};
`;

export const SelectWrapper = styled.div`
  .Select-control {
    background: ${colors.common.grey};
    color: #65707b;
    border: 1px;
    border-style: solid;
    border-color: transparent !important;
    border-radius: 8px;
    &:hover: {
      border-color: ${border.hover} !important;
    }
  }

  .Select.is-disabled > .Select-control {
    ${disabled}
    &:hover {
      border-color: ${border.normal} !important;
    }
  }

  .Select-multi-value-wrapper {
    height: 100%;
  }

  .Select-input {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .Select-input > input {
    box-sizing: border-box !important;
    display: inline-block;
    align-items: center;

    width: 100% !important;
    height: 100%;
    padding: 28px 12px 8px 12px;
    caret-color: ${colors.common.blue};

    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const AdditionalInfo = styled.div`
  margin-top: 4px;

  font-size: 10px;
  line-height: 12px;
  color: ${colors.common.grey4};
`;
