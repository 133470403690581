//@flow
import React from 'react';

import Failed from '../../../Components/Blocks/Failed';
import Request from '../../../Components/Blocks/Request';
import Succeed from '../../../Components/Blocks/Succeed';
import { withCloseInFrame } from '../../../Components/HOC';
import { formalizeMsisdn } from '../../../Helpers/Normalize';
import PaymentService from '../../../Services/PaymentService';
import pushAutopayment from '../Scenario/pushAutopayment';

type TProps = {
  /**ID платежа статус которого надо проверить и отобразить на экране */
  paymentId: string,
  /**Текст неуспешного сообщения в случае, если платеж в статусе Rejected */
  failedMessage: string,
  /**Обработчик закрытия окна формы */
  onClose: Function,
};

type TState = {
  payment: ?{
    msisdn: string,
    maskedPan: string,
  },
  isCardBound: boolean,
  error: string,
  isAutopaymentRequested: boolean,
};

class AddAutopaymentResult extends React.PureComponent<TProps, TState> {
  state = {
    payment: null,
    error: '',
    isCardBound: false,
    isAutopaymentRequested: false,
  };

  async resolvePayment() {
    const { paymentId } = this.props;
    let payment;
    let isCardBound = false;
    let isAutopaymentRequested = false;

    try {
      if (paymentId) {
        const { status, reason } = await PaymentService.getPaymentExactStatus({
          paymentId,
          expectedStatuses: ['Succeed', 'Rejected'],
        });

        if (status === 'Succeed') {
          payment = await PaymentService.getPaymentInfo({ paymentId });
        } else {
          const error = reason ? reason.description : '';
          throw new Error(error);
        }

        let cardId;

        try {
          var response = await PaymentService.getCardLoop({ paymentId });
          cardId = response.cardId;
          isCardBound = true;
        } catch (error) {}

        if (isCardBound) {
          var sessionStorageItem = sessionStorage.getItem(paymentId);

          if (sessionStorageItem) {
            var obj = JSON.parse(sessionStorageItem);

            try {
              await pushAutopayment({
                cardId: cardId,
                msisdn: obj.msisdn,
                amount: obj.amount,
                firstDateUtc: obj.firstDateUtc,
                billingServiceId: obj.serviceId,
                category: parseInt(obj.categoryId),
              });

              isAutopaymentRequested = true;

              this.setState({ payment: { msisdn: obj.msisdn } });
            } catch (error) {}
          }
        }

        this.setState({
          payment: {
            msisdn: this.state.payment ? this.state.payment.msisdn : payment.msisdn,
            maskedPan: payment.maskedPan,
          },
          isCardBound,
          isAutopaymentRequested,
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      this.setState({
        error: error.message,
      });
    }
  }

  componentDidMount() {
    this.resolvePayment();
  }

  _isRequest() {
    return this.props.paymentId && !this.state.error && !this.state.payment;
  }

  _isPaymentInfo() {
    return this.state.payment && !this.state.error;
  }

  render() {
    const { isAutopaymentRequested, isCardBound, payment, error } = this.state;
    const { msisdn = '', maskedPan } = payment || {};

    const { onClose } = this.props;

    const cardBoundSuccessMsg = 'Карта привязана';
    const cardBoundFailMsg = 'Не удалось привязать карту';
    const autopaymentRequestedSuccessMsg = 'Заявка на создание автоплатежа принята';
    const autopaymentRequestedFailMsg = 'Не удалось подключить автоплатёж';

    let resultTopic;

    if (isCardBound && isAutopaymentRequested) {
      resultTopic = `${cardBoundSuccessMsg}. ${autopaymentRequestedSuccessMsg}`;
    } else if (isCardBound && !isAutopaymentRequested) {
      resultTopic = `${cardBoundSuccessMsg}. ${autopaymentRequestedFailMsg}`;
    } else {
      resultTopic = cardBoundFailMsg;
    }

    const successMsg = `Карта ${maskedPan} привязана к номеру ${formalizeMsisdn(msisdn)}`;

    const SucceedOrFail =
      isCardBound && isAutopaymentRequested ? (
        <Succeed
          {...{
            topic: resultTopic,
            message: successMsg,
            isSubmitButtonVisible: false,
          }}
        />
      ) : (
        <Failed topic={resultTopic} message="" buttonLabel={'Закрыть'} onClick={onClose} />
      );

    return (
      <>
        {this._isRequest() && <Request />}

        {this._isPaymentInfo() && SucceedOrFail}

        {error && <Failed topic={resultTopic} message="" buttonLabel={'Закрыть'} onClick={onClose} />}
      </>
    );
  }
}

export default withCloseInFrame(AddAutopaymentResult);
