import React from 'react';

import FieldTextInput from '../../../../../Components/Controls/TextInput/FieldTextInput';
import { Amount, Msisdn, PaymentInfoWrapper } from '../styled';
import { amountInputRef } from './refs';

const PaymentInfo = () => {
  const labelText = 'Сумма от 100 до 6000 ₽';

  return (
    <PaymentInfoWrapper>
      <Msisdn>
        <FieldTextInput type={'tel'} name={'msisdn'} maskType={'phoneFull'} label={'Номер телефона'} required />
      </Msisdn>
      <Amount>
        <FieldTextInput
          type={'tel'}
          name={'amount'}
          maskType={'amountInteger'}
          label={labelText}
          required
          maxLength={5}
          ref={amountInputRef}
        />
      </Amount>
    </PaymentInfoWrapper>
  );
};

export default React.memo(PaymentInfo);
