import React from 'react';
import { QRCode, QRCodeWrapper, QRCodeText } from './styled';
import Button from '../../Components/Controls/Button';

const WebView = (props) => {
  const { image, onClose, imageType } = props;
  const imageUrl = `data:${imageType};base64,${image}`;
  const cleanImage = imageUrl.replace(/(\r\n|\n|\r)/gm, '');
  return (
    <>
      <QRCodeWrapper>
        <QRCode image={cleanImage} />
        <QRCodeText>
          Отсканируйте QR-код для перехода <br /> в приложение своего банка
        </QRCodeText>
      </QRCodeWrapper>
      <Button
        label={'Закрыть'}
        onClick={onClose}
        ghost
        data-testid={'block-result-button-close'}
        style={{ marginTop: 32 }}
      />
    </>
  );
};

export default WebView;
