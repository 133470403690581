import yup from 'yup';
const amountSchema = yup
  .number()
  .nullable()
  .required('Обязательное поле')
  .test('amount-test', 'Некорректная сумма', (amount) => !amount || (amount >= 100 && amount <= 3000));

const firstDateUtcSchema = yup.date().required('Обязательное поле');

export const schemas = {
  amountSchema,
  firstDateUtcSchema,
};
