import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withQueryParams } from '../../HOC';
import { convertKopecksToRublesAsDecimal } from '../../../Helpers/Currency';
import { LightBlueBlock } from '../Common';

const AccountInfo = (props) => {
  const { queryParams } = props;
  const { balance, subscriptionFee } = queryParams || {};

  if (!balance && !subscriptionFee) return null;

  return (
    <LightBlueBlock>
      {balance && <div>Баланс — {convertKopecksToRublesAsDecimal(balance)} ₽</div>}
      {subscriptionFee && <div>Абонентская плата — {convertKopecksToRublesAsDecimal(subscriptionFee)} ₽</div>}
    </LightBlueBlock>
  );
};

export default compose(withRouter, withQueryParams)(AccountInfo);
