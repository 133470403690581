import * as React from 'react';
import { Buttons } from '../../../../../Components/Blocks/DetailsPanel';
import { CardInfoButtonBox } from '../styled';
import Button from '../../../../../Components/Controls/Button';
import SavePayButton from '../../../../../Components/Blocks/SavePayButton';
import SecureBadges from '../../../../../Components/Blocks/SecureBadges';
import { withMobile } from '../../../../../Components/HOC';
import Oferta from '../../../../../Components/Blocks/Oferta';

const CardInfo = (props) => {
  return (
    <>
      <CardInfoButtonBox>
        <SavePayButton>
          <Buttons>
            <Button
              label={'Оплатить картой'}
              type={'submit'}
              disabled={!props.isValid}
              big={props.isMobile}
              alternate
            />
          </Buttons>
        </SavePayButton>
      </CardInfoButtonBox>
      <Oferta buttonLabel="Оплатить картой" />
      <SecureBadges />
    </>
  );
};

export default withMobile(CardInfo);
