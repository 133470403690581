import React from 'react';
import { FormContainer, Row } from '../../../../../Components/Blocks/Form';
import Panel from '../../../../../Components/Blocks/Panel';
import ReceiptElectron from '../../../../../Components/Controls/ReceiptElectron';

import PaymentInfo from './PaymentInfo';
import BranchesList from './BranchesList';
import CardFields from '../../../../../Components/Blocks/CardFields';
import { BlockTitle, GrayBlock } from '../../../../../Components/Blocks/Common';
import { FieldsWrapper } from '../styled';

const RequisitesPanel = (props) => {
  const {
    setFieldValue,
    values,
    branchesList,
    onBranchesListChanged,
    isBranchDisabled,
    isSubscriberAccountDisabled,
    isAmountDisabled,
  } = props;

  return (
    <Panel width="351px">
      <FormContainer>
        <FieldsWrapper>
          <PaymentInfo
            setFieldValue={setFieldValue}
            isSubscriberAccountDisabled={isSubscriberAccountDisabled}
            isAmountDisabled={isAmountDisabled}
          />
          <BranchesList
            branchesList={branchesList}
            onBranchesListChanged={onBranchesListChanged}
            disabled={isBranchDisabled}
          />
        </FieldsWrapper>
        <BlockTitle>Реквизиты для пополнения</BlockTitle>
        <GrayBlock>
          <CardFields setFieldValue={setFieldValue} />
        </GrayBlock>

        <BlockTitle>Куда отправить электронный чек?</BlockTitle>
        <Row>
          <ReceiptElectron
            type={values.electronReceiptType}
            emailInputName={'electronReceiptEmail'}
            mobileInputName={'electronReceiptMobile'}
            receiptTypeRadioName={'electronReceiptType'}
          />
        </Row>
      </FormContainer>
    </Panel>
  );
};

export default RequisitesPanel;
