import styled from 'styled-components';
import { breakpoints, colors } from '../../../Constants/css';

export const Wrapper = styled.div`
  margin: 16px 0 0 0;
  padding: 0;

  font-size: 12px;

  color: ${colors.common.grey1};

  @media (min-width: ${breakpoints.tablet}) {
    margin: 4px 0 0 0;
    padding: 8px 0;
  }

  > a {
    outline: none;

    color: ${colors.common.grey1};

    &:hover {
      color: #444852;
    }
  }
`;
