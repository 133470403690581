import styled from 'styled-components';
import { breakpoints } from '../../../Constants/css';

export const FieldWidth = styled.div`
  width: 100%;
`;

export const CardFieldsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 8px;

  margin-top: 8px;

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 16px;
    column-gap: 16px;
  }
`;

export const MaskedCodeButton = styled.img`
  width: 22px;
  height: 13px;
  cursor: pointer;
`;
