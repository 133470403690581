/**
 * 'AcceptCode's Styled Components
 *
 * @flow
 */
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  flex: 1;
  flex-basis: auto; //Fix IE11
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.div`
  display: flex;
  margin-top: 12px;

  flex: 1;
  flex-basis: 100%; //Fix IE11
  align-items: center;
`;

export const Input = styled.div`
  flex: 1;
  flex-basis: auto; //Fix IE11
`;

export const Hint = styled.div`
  margin-top: 8px;
  align-self: flex-start;
`;
