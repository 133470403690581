import styled, { css } from 'styled-components';
import React from 'react';
import { breakpoints, colors } from '../../../../Constants/css';
import Button from '../../../../Components/Controls/Button';

export const Msisdn = styled.div`
  @media (min-width: ${breakpoints.mobile}) {
    min-width: 250px;
  }

  flex: 1;
  flex-basis: auto; //Fix IE11

  @media (max-width: ${breakpoints.mobile}) {
    margin: 0;

    align-self: stretch;
  }
`;

export const Amount = styled.div`
  @media (min-width: ${breakpoints.mobile}) {
    min-width: 250px;
  }

  flex: 1;
  flex-basis: auto; //Fix IE11

  @media (max-width: ${breakpoints.mobile}) {
    margin: 0;

    align-self: stretch;
  }
`;

export const BindBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;

  @media (max-width: ${breakpoints.tablet}) {
    font-size: 15px;
  }
`;

export const BindText = styled.p`
  margin-top: 0;
  margin-bottom: 0;

  white-space: pre-line;
  font-size: 15px;
`;

export const BindHint = styled.div`
  margin-top: 12px;
  font-size: 12px;

  color: ${colors.fail};
`;

export const SBPTextBox = styled.div`
  margin-top: 30px;
`;

export const InfoWrapper = styled.div`
  margin-top: 24px;
  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 12px;
  }
`;

export const SBPLink = styled.a`
  font-weight: bold;
  color: #000;
  text-decoration: none;
  &:hover {
    color: #444852;
  }
`;

export const SBPInfoDescription = styled.div`
  margin-top: 4px;
  padding: 8px 0;

  color: ${colors.common.grey1};
  font-size: 12px;

  a {
    outline: none;
    color: #000000;

    &:hover {
      color: #444852;
    }
  }
`;

const sberPayButtonDisabled = css`
  cursor: default;
  opacity: 0.6;
`;

export const SberPayButton = styled((props) => <Button {...props} />)`
  background-image: linear-gradient(150deg, rgb(242, 234, 0) 0%, rgb(3, 211, 29) 35%, rgb(15, 182, 254) 100%);

  ${(p) => p.disabled && sberPayButtonDisabled};
`;

export const PaymentInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  @media (min-width: ${breakpoints.tablet}) {
    row-gap: 16px;
  }
`;

export const SBPContentWrapper = styled.div`
  margin-top: 24px;
  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 32px;
  }
`;

export const BindInfo = styled.div`
  display: flex;
  padding: 8px 0;

  font-size: 12px;
  color: ${colors.common.grey1};
`;

export const CardBindWrapper = styled.div`
  margin-top: 12px;
`;

export const BindCodeBox = styled.div`
  margin-top: 4px;
`;
