/**
 * 'Autopayment's Styled Components
 *
 * @flow
 */
import styled from 'styled-components';
import { breakpoints } from '../../../../../../Constants/css';

export const Msisdn = styled.div`
  width: 100%;
  min-width: 200px;
  margin: 0;

  @media (min-width: ${breakpoints.tablet}) {
    width: calc(50% - 8px);
    margin: 0;

    align-self: stretch;
  }
`;

export const Row = styled.div`
  display: flex;

  align-items: center;
`;

export const PaymentInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  @media (min-width: ${breakpoints.tablet}) {
    row-gap: 16px;
  }
`;
