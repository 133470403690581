/**
 * 'AcceptCode', 'Initialize's Element
 *
 * @flow
 */
import React from 'react';

import { Box, Hint, Input, Label, Wrapper } from './styled';

type TProps = {
  label: string,
  hint?: string,
  children: any /** React.ReactNode */,
};

const AcceptCode = (props: TProps) => {
  const { label, hint, children } = props;

  return (
    <Wrapper>
      <Box>
        <Input>{children}</Input>
        {label && <Label>{label}</Label>}
      </Box>
      {hint && <Hint data-testid={'remainingQuantity'}>{hint}</Hint>}
    </Wrapper>
  );
};

export default React.memo(AcceptCode);
