import React from 'react';
import { compose } from 'recompose';

import Failed from '../../Components/Blocks/Failed';
import { withFrame, withQueryParams, withThreeDS, withErrorsHandle, withMobile } from '../../Components/HOC';
import Layout from '../../Components/Blocks/Layout';
import { defaultAmount } from '../../Constants/amount';
import { convertKopecksToRublesAsInteger, convertStringToBoolean } from '../../Helpers/Currency';
import { useReCaptcha } from '../../hooks/useReCaptcha';
import Result from '../_shared/Result';
import Form from './Form';
import useYandexCaptcha from '../../hooks/useYandexCaptcha';
import { SBP_NOT_ALLOWED_CHANNELS, sbpIsActive } from '../../Constants/sbp';
import { NO_CARD_CHANNELS } from '../../Constants/card';
import { formalizeMsisdn } from '../../Helpers/Normalize';

const TopUpSwitcher = (props) => {
  const { criticalError, queryParams, isMobile } = props;
  const {
    paymentid,
    msisdn,
    email,
    mobile,
    elementIsDisabled,
    utm_source,
    utm_medium,
    mode,
    channel,
    newCard,
    sbpOff,
    closeable,
    authToken,
  } = queryParams;

  const isNewCard = convertStringToBoolean(newCard);
  const isSbpOff = convertStringToBoolean(sbpOff);
  const isCloseable = convertStringToBoolean(closeable);

  const isSbpActive = !SBP_NOT_ALLOWED_CHANNELS.includes(channel) && sbpIsActive && !isSbpOff && !isNewCard;

  const hasToken = Boolean(authToken);
  const areNoCardChannels = NO_CARD_CHANNELS.length === 0;
  const hasChannel = Boolean(channel);
  const isChannelInNoCardChannels = NO_CARD_CHANNELS.includes(channel);

  const isCardActive = hasToken || areNoCardChannels || (hasChannel && !isChannelInNoCardChannels);

  const isTopUpDisabled = !isSbpActive && !isCardActive;

  const titleCentered = (criticalError || isTopUpDisabled) && !isCloseable;

  const amount = queryParams.amount || defaultAmount;

  const { handleReCaptchaVerify } = useReCaptcha();
  const { handleYandexCaptchaVerify, ...restYandexCaptcha } = useYandexCaptcha();

  const retry = () => props.clearError();

  const onError = (error) => props.handleError(error);

  const onThreeDSSuccess = async (threeDSData) => await props.threeDS.redirect(threeDSData);
  const onFrameSuccess = async (threeDSData) => await props.handlePostFrame(threeDSData);

  const onSBPDeepLink = (deepLink, values) => {
    props.history.push({
      pathname: '/sbpdeeplink',
      state: { deepLink, values },
    });
  };

  const onAreqSucceed = (paymentid) => props.history.push(`/?paymentid=${paymentid}`);

  const onSberPaySuccess = (paymentId, { msisdn, email, amount }) => {
    let urlParams = `paymentId=${paymentId}&msisdn=${msisdn}&amount=${amount}`;
    if (email) {
      urlParams += `&email=${email}`;
    }

    props.history.push(`/sberpay?${urlParams}`);
  };

  const topupMessage = (payment) =>
    `Номер ${formalizeMsisdn(payment.msisdn)} пополнен ${isMobile ? '\n' : ''} на сумму ${payment.amount} ₽`;

  return (
    <Layout title={'Пополнение баланса'} titleCentered={titleCentered}>
      {isTopUpDisabled && (
        <Failed
          topic="Уважаемый абонент!"
          message={`Оплата в неавторизованной зоне временно ограничена. \nПополните баланс из Личного кабинета или мобильного приложения t2.`}
        />
      )}
      {!isTopUpDisabled && (
        <>
          {criticalError && (
            <Failed
              topic={'Не удалось пополнить баланс'}
              message={criticalError}
              onClick={retry}
              buttonLabel={'Попробовать еще раз'}
            />
          )}
          {!criticalError && !paymentid && (
            <Form
              msisdn={msisdn}
              amount={convertKopecksToRublesAsInteger(parseInt(amount, 10))}
              email={email}
              onError={onError}
              onThreeDSSuccess={onThreeDSSuccess}
              onSBPDeepLink={onSBPDeepLink}
              onAreqSucceed={onAreqSucceed}
              mobile={mobile}
              handleReCaptchaVerify={handleReCaptchaVerify}
              handleYandexCaptchaVerify={handleYandexCaptchaVerify}
              yandexCaptcha={restYandexCaptcha}
              onFrameSuccess={onFrameSuccess}
              onSberPaySuccess={onSberPaySuccess}
              elementIsDisabled={convertStringToBoolean(elementIsDisabled)}
              isMobile={Boolean(utm_source && utm_medium && mode)}
              isSbpActive={isSbpActive}
              isCardActive={isCardActive}
              channel={channel}
              authToken={authToken}
            />
          )}
          {paymentid && (
            <Result
              successTopic={'Баланс пополнен'}
              successMessage={topupMessage}
              failedTopic={'Не удалось пополнить баланс'}
              failedMessage={'Пожалуйста, попробуйте ещё раз'}
              paymentId={paymentid}
              paymentType={'topup'}
              isBindCard={false}
            />
          )}
        </>
      )}
    </Layout>
  );
};

export default compose(withMobile, withQueryParams, withThreeDS, withFrame, withErrorsHandle)(TopUpSwitcher);
