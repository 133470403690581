import React from 'react';
import { compose } from 'recompose';

import Layout from '../../Components/Blocks/Layout';
import { withErrorsHandle, withQueryParams } from '../../Components/HOC';
import { deleteFirstSevenCharacterAccordingToLength } from '../../Helpers/Normalize';
import AddAutopaymentForm from '../_shared/Succeed/AddAutopayment/Form';
import Failed from '../../Components/Blocks/Failed';

class Autopayment extends React.PureComponent {
  retry = () => {
    this.props.clearError();
  };

  render() {
    const { handleError, criticalError } = this.props;

    return (
      <Layout title={'Создание автоплатежа'}>
        {criticalError && <Failed message={criticalError} onClick={this.retry} buttonLabel={'Попробовать еще раз'} />}

        {!criticalError && (
          <AddAutopaymentForm
            {...{
              msisdn: deleteFirstSevenCharacterAccordingToLength(this.props.queryParams.msisdn),
              subscriber: deleteFirstSevenCharacterAccordingToLength(this.props.queryParams.subscriber),
              onFail: handleError,
              clientType: this.props.queryParams.clientType,
              auth: this.props.queryParams.auth,
              closeable: this.props.queryParams.closeable,
              authToken: this.props.queryParams.authToken,
              redirect: (redirectUrl) => {
                this.props.history.push(redirectUrl);
              },
            }}
          />
        )}
      </Layout>
    );
  }
}

export default compose(withQueryParams, withErrorsHandle)(Autopayment);
