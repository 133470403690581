import React from 'react';
import Button from '../Button';
import { images } from '../../../Constants/css';
import { SBPLabel } from './styled';

const SBPButton = (props) => {
  const label = (
    <SBPLabel>
      Оплатить СБП
      <img src={images.sbpLogo} height="24px" alt="" />
    </SBPLabel>
  );

  return <Button {...props} label={label} alternate />;
};

export default SBPButton;
