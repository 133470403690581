import { compose } from 'recompose';
import { ValidationError } from '../../../../../Constants/errors';
import { convertKopecksToRublesAsDecimal } from '../../../../../Helpers/Currency';
import withCommonRequestProcess from '../../../../../Helpers/withCommonRequestProcess';
import AutopaymentService from '../../../../../Services/AutopaymentService';
import { CATEGORY_SUBSCRIPTIONFEE } from '../Form';

export const pullSubscriptionFee = async (props) => {
  let subscriptionFee;

  const { values, setValues, setSubscriptionFee } = props;
  const msisdn = props.msisdn ? props.msisdn : props.values.msisdn;

  try {
    await new Promise((next) => setTimeout(next, 2000));
    subscriptionFee = await AutopaymentService.getSubscriptionFee({ msisdn });
    const { amount, nextDate } = subscriptionFee;
    if (amount) {
      subscriptionFee.amount = convertKopecksToRublesAsDecimal(amount);
    } else {
      subscriptionFee.amount = null;
    }

    if (!nextDate) {
      subscriptionFee.nextDate = null;
    }
  } catch (e) {
    if (!(e instanceof ValidationError)) {
      throw new ValidationError({
        commonErrors: [`При запросе доступных услуг произошла ошибка, повторите запрос позднее.`],
      });
    }

    throw e;
  }

  setSubscriptionFee(subscriptionFee);
  setValues({
    ...values,
    amount: subscriptionFee.amount,
    category: CATEGORY_SUBSCRIPTIONFEE,
  });
};

const adapterCommonRequestProcess = (func) => {
  return (props) => {
    const { setErrors, fetching, setCommonError, onFail: onError } = props;
    func(
      {
        setErrors,
        fetching,
        setCommonError,
        onError,
      },
      props
    );
  };
};

export default compose(adapterCommonRequestProcess, withCommonRequestProcess)(pullSubscriptionFee);
