import React from 'react';
import { images } from '../../../Constants/css';
import { Wrapper } from './styled';

const SecureSign = () => (
  <Wrapper>
    <img alt={''} src={images.paySave} />
    <span>
      Это абсолютно безопасно - данные <br /> вашей карты будут надежно храниться <br /> в зашифрованном виде
    </span>
  </Wrapper>
);

export default SecureSign;
