import * as React from 'react';

import { Note, Topic, TotalTopicAmount, TotalTopicBox, Wrapper } from '../../../../../Components/Blocks/DetailsPanel';
import { withMobile } from '../../../../../Components/HOC';
import Panel from '.././../../../../Components/Blocks/Panel';
import { InfoWrapper } from '../styled';
import CardInfo from './CardInfo';
import SBPInfo from './SBPInfo';
import YandexCaptcha from '../../../../../Components/Blocks/YandexCaptcha';
import { isCaptchaEnabled, selectedCaptcha } from '../../../../../Constants';
import { checkSelectedCaptcha } from '../../../../../Helpers';

const TotalPanel = (props) => {
  const {
    errors,
    values,
    isValid,
    payType,
    isMobile,
    onClickCardPay,
    onClickSBPPay,
    onClickSberPay,
    isSberPayButtonVisible,
    isSberPayValid,
    yandexCaptcha,
    onYandexCaptchaSuccess,
  } = props;
  const { visible, resetToken, handleChallengeHidden, handleReset } = yandexCaptcha;

  const isYandexCaptchaEnabled = checkSelectedCaptcha(isCaptchaEnabled, selectedCaptcha, 'yandex');

  const cardPay = payType === 'card';
  const info = cardPay ? (
    <CardInfo
      isMobile={isMobile}
      isValid={isValid}
      isSberPayValid={isSberPayValid}
      onClickCardPay={onClickCardPay}
      onClickSberPay={onClickSberPay}
      isSberPayButtonVisible={isSberPayButtonVisible}
    />
  ) : (
    <SBPInfo isMobile={isMobile} isValid={isValid} onClickSBPPay={onClickSBPPay} />
  );

  return (
    <Panel alternate>
      <Wrapper>
        <Topic>
          <TotalTopicBox>
            <div>
              <div>Итого к зачислению</div>
              <Note>Без комиссии</Note>
            </div>
            <TotalTopicAmount>{`${!errors.amount ? values.amount : '0'} ₽`}</TotalTopicAmount>
          </TotalTopicBox>
        </Topic>

        <InfoWrapper>{info}</InfoWrapper>
      </Wrapper>
      {isYandexCaptchaEnabled && (
        <YandexCaptcha
          onSuccess={onYandexCaptchaSuccess}
          visible={visible}
          resetToken={resetToken}
          onChallengeHidden={handleChallengeHidden}
          onTokenExpired={handleReset}
        />
      )}
    </Panel>
  );
};

export default withMobile(React.memo(TotalPanel));
