import React from 'react';
import { compose } from 'recompose';

import Failed from '../../../../Components/Blocks/Failed';
import { withCloseInFrame, withErrorsHandle, withMobile } from '../../../../Components/HOC';
import SucceededWithAutopayment from '../Autopayment';
import BindCardForm from './Form';
import { formalizeMsisdn } from '../../../../Helpers/Normalize';

const SucceedWithBindCard = (props) => {
  const {
    amount,
    msisdn,
    paymentId,
    criticalError,
    handleError,
    onClose,
    paymentType,
    topic,
    isMobile,
    maskedPan,
    message,
  } = props;

  const [isBound, setIsBound] = React.useState(false);

  const onBindCardSuccess = () => setIsBound(true);

  const bindCardMessage = `Карта ${maskedPan} привязана к номеру ${isMobile ? '\n' : ''} ${formalizeMsisdn(msisdn)}`;

  return (
    <>
      {criticalError && (
        <Failed
          topic={'Не удалось привязать карту'}
          message={props.criticalError}
          onClick={onClose}
          buttonLabel={'Закрыть'}
        />
      )}
      {!criticalError && !isBound && (
        <BindCardForm
          {...{
            topic,
            message,
            msisdn,
            paymentId,
            onBindCardSuccess,
            onBindCardFail: handleError,
          }}
        />
      )}
      {isBound && (
        <SucceededWithAutopayment
          {...{
            topic: 'Карта привязана',
            message: bindCardMessage,
            msisdn,
            amount,
            paymentId,
            paymentType,
          }}
        />
      )}
    </>
  );
};

export default compose(withMobile, withErrorsHandle, withCloseInFrame)(SucceedWithBindCard);
