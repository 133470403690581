import React from 'react';
import AcceptCode from '../AcceptCode';
import { formalizeMsisdn } from '../../../Helpers/Normalize';
import { FieldTextInput } from '../../Controls/TextInput';

const BindCode = (props) => {
  const { msisdn, remainingQuantity, isBind, fieldName, alternate } = props;

  if (isBind) {
    const hint = remainingQuantity ? `У вас осталась ${remainingQuantity} попытки ввода` : '';
    return (
      <AcceptCode label={`Код отправлен на номер ${formalizeMsisdn(msisdn)}`} hint={hint}>
        <FieldTextInput
          name={fieldName}
          label={'Код из SMS'}
          type={'tel'}
          required
          maxLength={'4'}
          maskType={'integer'}
          alternate={alternate}
        />
      </AcceptCode>
    );
  }
  return null;
};

export default React.memo(BindCode);
