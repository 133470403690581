import { CriticalError, ValidationError } from '../../../../Constants/errors';
import PaymentService from '../../../../Services/PaymentService';
import SubscriberService from '../../../../Services/SubscriberService';
import { setAcceptCodeId, setPaymentId, setSubscriberId } from './makeLoopAuth';
import { TPaymentStatusResponse } from '../../../../Constants/types';

const makeThreeDSAuth = async (values, formikBag) => {
  const { props, setFieldValue, setFieldTouched } = formikBag;
  const { categoryId, amount, serviceId, firstDateUtc, isAlien, apMsisdn, authToken } = props;
  const { subscriberInfo } = values;
  const { cardData, clientType, auth, isAutopayment, subscriber } = subscriberInfo;
  const { msisdn } = cardData;

  let paymentInfo;
  let callbackRoute = 'addautopayment';

  if (isAutopayment && !auth) {
    //todo replace method prepareThreeDS
    paymentInfo = await PaymentService.prepareThreeDSNotConfirmed(
      {
        ...cardData,
        callbackRoute,
      },
      { 'Auth-Token': authToken || '' }
    );
  } else {
    let callbackRoute;

    if (isAutopayment) {
      callbackRoute = 'addautopayment';
    } else if (isAlien) {
      callbackRoute = 'bindcardalien';
    } else if (clientType) {
      callbackRoute = 'b2bbindcard';
    } else {
      callbackRoute = 'bindcard';
    }

    paymentInfo = await PaymentService.prepare3ds(
      {
        ...cardData,
        subscriberId: subscriber.subscriberId,
        callbackRoute,
      },
      { 'Auth-Token': authToken || '' }
    );
  }

  const { paymentId } = paymentInfo;

  const handleWaitingLoopStatus = async () => {
    const { acceptCodeId } = await PaymentService.getLoop({
      paymentId,
    });

    setFieldValue('withLoop', true);
    setFieldTouched('acceptCode', false);
    setSubscriberId(subscriber.subscriberId);
    setPaymentId(paymentId);
    setAcceptCodeId(acceptCodeId);
  };

  const handleRejectStatus = async (payment: TPaymentStatusResponse) => {
    const { reason, isLoopEnabled } = payment;

    if (clientType || !auth || !isLoopEnabled) {
      if (reason) {
        throw new ValidationError({
          commonErrors: [reason.description],
        });
      } else {
        throw new ValidationError({
          commonErrors: [`Произошла техническая ошибка. Данная карта не может быть привязана.`],
        });
      }
    } else {
      await SubscriberService.addCardToSubscriber({
        subscriberId: subscriber.subscriberId,
        ...cardData,
      });
    }
    const { paymentId } = await PaymentService.prepareLoop({
      msisdn,
      ...cardData,
    });

    const { status } = await PaymentService.getPaymentExactStatus({
      paymentId,
      expectedStatuses: ['WaitingLoop', 'Rejected'],
    });

    if (isAutopayment) {
      if (typeof Storage !== 'undefined') {
        sessionStorage.setItem(
          paymentId,
          JSON.stringify({ categoryId, amount, serviceId, firstDateUtc, msisdn: apMsisdn })
        );
      } else {
        console.error('SessionStorage disabled');
        throw new CriticalError({
          commonErrors: [`Произошла техническая ошибка. Автоплатеж не может быть подключен.`],
        });
      }
    }

    if (status === 'WaitingLoop') {
      await handleWaitingLoopStatus();
    } else {
      throw new ValidationError({
        commonErrors: [`Произошла техническая ошибка. Попробуйте запрос позже.`],
      });
    }
  };

  const handleSucceedStatus = () => {
    var sessionStorageObj = { categoryId, amount, serviceId, firstDateUtc, msisdn: apMsisdn };
    sessionStorage.setItem(paymentId, JSON.stringify(sessionStorageObj));
    props.onLoopSuccess(paymentId, isAutopayment, isAlien);
  };

  const handleWaitingFrameStatus = async () => {
    const frameData = await PaymentService.getFrame({ paymentId });
    await props.onFrameSuccess(frameData);

    const payment = await PaymentService.getPaymentExactStatus({
      paymentId,
      expectedStatuses: ['Waiting3Ds', 'Pending', 'Rejected', 'Succeed'],
      tries: 10,
    });

    const { status, reason } = payment;

    switch (status) {
      case 'Waiting3Ds': {
        await handleWaiting3DsStatus();
        break;
      }
      case 'Pending': {
        await paymentExactStatus('WaitingFrame');
        break;
      }
      case 'Rejected': {
        await handleRejectStatus(payment);
        break;
      }
      case 'Succeed': {
        handleSucceedStatus();
        break;
      }
      case 'WaitingFrame': {
        await PaymentService.confirmFrameThreeDS({ paymentId, threeDSCompInd: 'N' });
        await paymentExactStatus('WaitingFrame');
        break;
      }
      default:
        if (reason) {
          throw new ValidationError({
            commonErrors: [reason.description],
          });
        } else {
          throw new ValidationError({
            commonErrors: ['Произошла техническая ошибка, повторите запрос позднее.'],
          });
        }
    }
  };

  const handleWaiting3DsStatus = async () => {
    const threeDSData = await PaymentService.getThreeDS({
      paymentId,
    });

    if (isAutopayment) {
      if (typeof Storage !== 'undefined') {
        var sessionStorageObj = { categoryId, amount, serviceId, firstDateUtc, msisdn: apMsisdn };

        sessionStorage.setItem(paymentId, JSON.stringify(sessionStorageObj));
      } else {
        console.error('SessionStorage disabled');
        throw new CriticalError({
          commonErrors: [`Произошла техническая ошибка. Автоплатеж не может быть подключен.`],
        });
      }
    }

    await props.onThreeDSSuccess(threeDSData);
  };

  const paymentExactStatus = async (excl) => {
    const expectedStatuses = ['Waiting3Ds', 'WaitingFrame', 'Rejected', 'Succeed'].filter((sts) => sts !== excl);
    const payment = await PaymentService.getPaymentExactStatus({
      paymentId,
      expectedStatuses,
    });
    const { status: statusByRequest } = payment;

    const status = expectedStatuses.find((expectedSts) => expectedSts === statusByRequest);

    switch (status) {
      case 'Succeed':
        handleSucceedStatus();
        break;
      case 'Waiting3Ds':
        await handleWaiting3DsStatus();
        break;
      case 'WaitingFrame':
        await handleWaitingFrameStatus();
        break;
      case 'Rejected':
        await handleRejectStatus(payment);
        break;
      default:
        throw new ValidationError({
          commonErrors: [`Произошла техническая ошибка. Попробуйте запрос позже.`],
        });
    }
  };

  await paymentExactStatus();
};

export default makeThreeDSAuth;
