/**
 * 'CardBinder's Styled Components
 *
 * @flow
 */
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;

  flex-direction: column;
`;

export const BindCodeBox = styled.div`
  max-width: 351px;
`;
