/**
 * 'Box', 'Switcher's Element <=> 'Box's Styled Components
 *
 * @flow
 */
import styled, { css } from 'styled-components';

import { colors } from '../../../../Constants/css';

const themes = {
  off: css`
    display: inline-flex;
    align-items: center;
    border: 2px solid ${colors.border.second};
    border-radius: 18px;
    min-width: 52px;
    height: 32px;

    background: ${colors.common.grey2};

    cursor: pointer;
  `,
  on: css`
    border-color: ${colors.common.blue};
    background: ${colors.common.blue};
  `,
  disabled: css`
    cursor: not-allowed;
  `,
};

const Box = styled.div`
  ${themes.off};

  ${(p) => p.on && themes.on};
  ${(p) => p.disabled && themes.disabled};

  transition: background-color border-color 0.2s linear;
`;

export default Box;
