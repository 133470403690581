/**
 * withT2Fonts HOC
 *
 * @flow
 */
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  @font-face {
    font-family: 'Rooftop';
    src: url('/assets/fonts/Rooftop-Regular.eot');
    src: url('/assets/fonts/Rooftop-Regular.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/Rooftop-Regular.woff') format('woff'),
      url('/assets/fonts/Rooftop-Regular.woff2') format('woff2'),
      url('/assets/fonts/Rooftop-Regular.ttf') format('truetype'),
      url('/assets/fonts/Rooftop-Regular.svg#regular') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Rooftop';
    src: url('/assets/fonts/Rooftop-Medium.eot');
    src: url('/assets/fonts/Rooftop-Medium.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/Rooftop-Medium.woff') format('woff'), url('/assets/fonts/Rooftop-Medium.woff2') format('woff2'),
      url('/assets/fonts/Rooftop-Medium.ttf') format('truetype'),
      url('/assets/fonts/Rooftop-Medium.svg#Medium') format('svg');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'HalvarBreit-ExtraBold';
    src: url('/assets/fonts/HalvarBreit-ExtraBold.eot');
    src: url('/assets/fonts/HalvarBreit-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/HalvarBreit-ExtraBold.woff') format('woff'),
      url('/assets/fonts/HalvarBreit-ExtraBold.woff2') format('woff2'),
      url('/assets/fonts/HalvarBreit-ExtraBold.ttf') format('truetype'),
      url('/assets/fonts/HalvarBreit-ExtraBold.svg#regular') format('svg');
    font-weight: 900;
    font-style: normal;
  }

  color: #000000;
  font: 15px/1.375 'Rooftop';

  @font-face {
    font-family: 'icomoon';
    src: url('/assets/fonts/icomoon.eot?dxy2th');
    src: url('/assets/fonts/icomoon.eot?dxy2th#iefix') format('embedded-opentype'),
      url('/assets/fonts/icomoon.ttf?dxy2th') format('truetype'),
      url('/assets/fonts/icomoon.woff?dxy2th') format('woff'),
      url('/assets/fonts/icomoon.svg?dxy2th#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
  }
`;

export default function withT2Fonts(Component: React$ElementType) {
  return function withT2FontsHOC(props: *) {
    return (
      <Wrapper>
        <Component {...props} />
      </Wrapper>
    );
  };
}
