import React from 'react';
import { compose } from 'recompose';

import Failed from '../../Components/Blocks/Failed';
import Layout from '../../Components/Blocks/Layout';
import {
  withCloseInFrame,
  withErrorsHandle,
  withFrame,
  withMobile,
  withQueryParams,
  withThreeDS,
} from '../../Components/HOC';
import { deleteFirstSevenCharacter, formalizeMsisdn } from '../../Helpers/Normalize';
import Result from '../_shared/Result';
import Form from './Form';

class BindCardPage extends React.PureComponent {
  retry = () => {
    this.props.clearError();
  };

  onError = (error) => {
    this.props.handleError(error);
  };

  onThreeDSSuccess = async (threeDSData) => {
    await this.props.threeDS.redirect(threeDSData);
  };

  onLoopSuccess = (paymentId, isAutopayment, isAlien) => {
    if (isAutopayment) {
      this.props.history.push(`addautopayment?paymentid=${paymentId}`);
    } else if (isAlien) {
      this.props.history.push(`bindcardalien?paymentid=${paymentId}`);
    } else {
      this.props.history.push(`bindcard?paymentid=${paymentId}`);
    }
  };

  onFrameSuccess = async (threeDSData) => await this.props.handlePostFrame(threeDSData);

  render() {
    const isAlien = this.props.match.path === '/bindcardalien';

    const failed = isAlien ? (
      <Failed
        topic={'Не удалось привязать карту'}
        message={this.props.criticalError}
        onClick={this.props.onClose}
        buttonLabel={'Закрыть'}
      />
    ) : (
      <Failed
        topic={'Не удалось привязать карту'}
        message={this.props.criticalError}
        onClick={this.retry}
        buttonLabel={'Попробовать еще раз'}
      />
    );

    const successMessage = (payment) =>
      `Карта ${payment.maskedPan} привязана к номеру ${this.props.isMobile ? '\n' : ''} ${formalizeMsisdn(
        payment.msisdn
      )}`;

    return (
      <Layout title={'Добавление карты'}>
        {this.props.criticalError && failed}

        {!this.props.criticalError && !this.props.queryParams.paymentid && (
          <Form
            msisdn={deleteFirstSevenCharacter(this.props.queryParams.msisdn)}
            apMsisdn={deleteFirstSevenCharacter(this.props.queryParams.apMsisdn)}
            email={this.props.queryParams.email}
            clientType={this.props.queryParams.clientType}
            onError={this.onError}
            onLoopSuccess={this.onLoopSuccess}
            onThreeDSSuccess={this.onThreeDSSuccess}
            onFrameSuccess={this.onFrameSuccess}
            categoryId={this.props.queryParams.categoryId}
            amount={this.props.queryParams.amount}
            serviceId={this.props.queryParams.serviceId}
            firstDateUtc={this.props.queryParams.firstDateUtc}
            auth={this.props.queryParams.auth === 'true' ? true : false}
            authToken={this.props.queryParams.authToken}
            isAlien={isAlien}
            buttonLabel={this.props.queryParams.textButton || 'Сохранить карту'}
          />
        )}

        {this.props.queryParams.paymentid && (
          <Result
            successTopic={'Карта привязана'}
            successMessage={successMessage}
            failedTopic={'Не удалось привязать карту'}
            failedMessage="Пожалуйста, попробуйте позже"
            paymentId={this.props.queryParams.paymentid}
            isBindCard={!isAlien}
            isAlien={isAlien}
          />
        )}
      </Layout>
    );
  }
}

export default compose(
  withMobile,
  withQueryParams,
  withThreeDS,
  withErrorsHandle,
  withCloseInFrame,
  withFrame
)(BindCardPage);
