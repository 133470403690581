import React, { useState } from 'react';

import { Row } from '../Form';
import { CardFieldsWrapper, FieldWidth, MaskedCodeButton } from './styled';
import { FieldTextInput } from '../../Controls/TextInput';
import { images } from '../../../Constants/css';
import { cvvInputRef, expirationDateInputRef } from './refs';

const CardFields = (props) => {
  const { setFieldValue } = props;

  const [isCodeVisible, setIsCodeVisible] = useState(false);
  const [isShowPasswordVisible, setIsShowPasswordVisible] = useState(false);

  const onBlur = (event) => {
    setFieldValue('pan', event.target.value.trim(), false);

    // Fix problem with react-text-mask lib, that add space by cardNumber mask
    event.target.value = event.target.value.trim();
  };

  const handleFocusCVV = () => {
    setIsShowPasswordVisible(true);
  };

  const handleBlurCVV = (event) => {
    const value = event.target.value;
    if (!value) {
      setIsShowPasswordVisible(false);
    }
  };

  const handleChangeCodeVisibility = () => {
    setIsCodeVisible((prev) => !prev);
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const cvvInputSuffix = isShowPasswordVisible && (
    <MaskedCodeButton
      src={isCodeVisible ? images.openedEye : images.closedEye}
      isCodeVisible={isCodeVisible}
      onClick={handleChangeCodeVisibility}
      onMouseDown={handleMouseDown}
    />
  );

  return (
    <>
      <Row>
        <FieldWidth>
          <FieldTextInput
            iconName="bank-card"
            type={'tel'}
            name={'pan'}
            maskType={'cardNumber'}
            label={'Номер карты'}
            prefix={<img src={images.bankCard} width={20} height={15} alt="Лого банковской карты" />}
            required
            autoComplete={'cc-number'}
            onBlur={onBlur}
            alternate
          />
        </FieldWidth>
      </Row>
      <CardFieldsWrapper>
        <FieldWidth>
          <FieldTextInput
            type="tel"
            name="expirationDate"
            maskType="cardDate"
            label="Срок действия"
            autoComplete="cc-exp"
            maxLength={5}
            ref={expirationDateInputRef}
            alternate
            required
          />
        </FieldWidth>
        <FieldWidth>
          <FieldTextInput
            type={isCodeVisible ? 'text' : 'password'}
            name="cvv"
            maskType="cardCVC4"
            label="CVV2/CVC2"
            autoComplete="cc-csc"
            maxLength={3}
            ref={cvvInputRef}
            suffix={cvvInputSuffix}
            alternate
            required
            onFocus={handleFocusCVV}
            onBlur={handleBlurCVV}
          />
        </FieldWidth>
      </CardFieldsWrapper>
    </>
  );
};

export default React.memo(CardFields);
