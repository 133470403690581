/**
 * 'Succeeded', 'Payment's View
 *
 * @flow
 */
import * as React from 'react';
import { compose } from 'recompose';

import Button from '../../Controls/Button';
import { withCloseInFrame } from '../../HOC';
import { Image, Message, Topic, Wrapper, AdditionalBox } from './styled';
import { images } from '../../../Constants/css';
import { Controls } from '../Common';

type TProps = {
  isSubmitButtonDisabled?: boolean,
  isSubmitButtonVisible?: boolean,
  submitButtonLabel?: string,
  message: string,
  onSubmit?: Function,
  onClose: Function,
  children: any /** React.ReactNode */,
  isMobile: boolean,
};

const Succeed = (props: TProps) => {
  return (
    <Wrapper data-testid={'block-result'}>
      <Image src={images.happyPanda} alt="Счастливая панда :)" />
      <Topic data-testid={'block-result-label'}>{props.topic || 'Успешно!'}</Topic>
      {props.message && <Message data-testid={'block-failed-message'}>{props.message}</Message>}
      {props.children ? <AdditionalBox>{props.children}</AdditionalBox> : null}
      <Controls>
        <>
          {props.isSubmitButtonVisible && (
            <Button
              disabled={props.isSubmitButtonDisabled}
              label={props.submitButtonLabel || ''}
              onClick={props.onSubmit}
              big
              data-testid={'block-result-button-submit'}
              alternate
            />
          )}
          <Button
            disabled={false}
            label={'Закрыть'}
            onClick={props.onClose}
            ghost
            big
            data-testid={'block-result-button-close'}
          />
        </>
      </Controls>
    </Wrapper>
  );
};

export default compose(withCloseInFrame)(React.memo(Succeed));
