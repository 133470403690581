import styled from 'styled-components';

export const SBPLabel = styled.div`
  display: flex
  justify-content: center;
  align-items: center;

  > img {
    margin-left: 12px;
  }
`;
