//@flow
import React from 'react';
import Failed from '../../../Components/Blocks/Failed';
import Request from '../../../Components/Blocks/Request';
import { withCloseInFrame } from '../../../Components/HOC';
import { formalizeMsisdn } from '../../../Helpers/Normalize';
import PaymentService from '../../../Services/PaymentService';

import Succeed from '../../../Components/Blocks/Succeed';

type TProps = {
  /**ID платежа статус которого надо проверить и отобразить на экране */
  paymentId: string,
  /**Текст неуспешного сообщения в случае, если платеж в статусе Rejected */
  failedMessage: string,
  /**Обработчик закрытия окна формы */
  onClose: Function,
};

type TState = {
  payment: ?{
    msisdn: string,
    maskedPan: string,
  },
  isCardBound: boolean,
  error: string,
};

class B2bResult extends React.Component<TProps, TState> {
  state = {
    payment: null,
    error: '',
    isCardBound: false,
  };

  async resolvePayment() {
    const { paymentId } = this.props;
    let payment;
    let isCardBound = false;

    try {
      if (paymentId) {
        const { status, reason } = await PaymentService.getPaymentExactStatus({
          paymentId,
          expectedStatuses: ['Succeed', 'Rejected'],
        });

        if (status === 'Succeed') {
          payment = await PaymentService.getPaymentInfo({ paymentId });
        } else {
          const error = reason ? reason.description : '';
          throw new Error(error);
        }
        try {
          await PaymentService.getCardLoop({ paymentId });
          isCardBound = true;
        } catch (error) {
          throw error;
        }

        this.setState({
          payment: {
            msisdn: payment.msisdn,
            maskedPan: payment.maskedPan,
          },
          isCardBound,
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      this.setState({
        error: error.message,
      });
    }
  }

  componentDidMount() {
    this.resolvePayment();
  }

  _isRequest() {
    return this.props.paymentId && !this.state.error && !this.state.payment;
  }

  _isPaymentInfo() {
    return this.state.payment && !this.state.error;
  }

  render() {
    const { isCardBound, payment, error, errorTopic } = this.state;
    const { msisdn = '', maskedPan } = payment || {};

    const { failedMessage, onClose } = this.props;

    const successMessage = `Карта ${maskedPan} привязана к номеру ${formalizeMsisdn(msisdn)}`;

    const SucceedOrFail = isCardBound ? (
      <Succeed
        {...{
          topic: 'Карта привязана',
          message: successMessage,
          isSubmitButtonVisible: false,
        }}
      />
    ) : (
      <Failed topic={errorTopic} message={error ? error : failedMessage} buttonLabel={'Закрыть'} onClick={onClose} />
    );

    return (
      <React.Fragment>
        {this._isRequest() && <Request />}

        {this._isPaymentInfo() && SucceedOrFail}

        {error && (
          <Failed
            topic={errorTopic}
            message={error ? error : failedMessage}
            buttonLabel={'Закрыть'}
            onClick={onClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withCloseInFrame(B2bResult);
