/**
 * 'TextInput's Styled Components
 *
 * @flow
 */
import styled, { css } from 'styled-components';
import { colors, fonts, rules } from '../../../Constants/css';

const { border, text } = colors;

// rules
const themes = {
  bordered: css`
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: 12px;
  `,
  disabled: css`
    background-color: #f9fafb;
    color: ${text.disabled};
    cursor: not-allowed;
  `,
  input: {
    active: css`
      padding-top: 28px;
    `,
    focused: css`
      border-color: ${border.second};
    `,
    invalid: css`
      background-color: ${colors.common.lightRed};

      :-webkit-autofill,
      :-webkit-autofill:hover,
      :-webkit-autofill:focus,
      :-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px ${colors.common.lightRed} inset !important;
      }
    `,
    hovered: css`
      border-color: ${border.hover};
    `,
    alternate: css`
      background: ${colors.common.white};
    `,
  },
  label: {
    active: css`
      font-size: 12px;
      line-height: 22px;
      white-space: normal;
      top: 8px;
    `,
    fixed: css`
      position: absolute;
      width: 24px;
      height: 24px;
      top: 19px;
      right: 16px;
      z-index: 1;
    `,
    invalid: css`
      color: ${colors.common.red};
    `,
  },
};

export const IconWrapper = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;

  background-color: ${colors.common.grey};
  padding: 0 11px;

  ${themes.bordered};

  :hover {
    ${(p) => !p.disabled && themes.input.hovered}
  }

  ${(p) => p.alternate && themes.input.alternate}
  ${(p) => p.borderless && rules.borderless};
  ${(p) => p.isFocused && themes.input.focused};
  ${(p) => p.errorText && themes.input.invalid};
  ${(p) => p.disabled && themes.disabled};
`;

export const InputWrapper = styled.div`
  flex: 1;
  position: relative;

  > input:-webkit-autofill,
  > input:-webkit-autofill:hover,
  > input:-webkit-autofill:focus,
  > input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${colors.common.grey} inset !important;
  }

  > input {
    display: block;
    width: 100%;
    height: 58px;
    min-width: 70px;

    padding: 8px 0px;

    font-family: ${fonts.alternate};
    font-size: 100%;
    color: #000;
    background-color: inherit;
    vertical-align: middle;
    line-height: 22px;

    outline: none;
    z-index: 2;
    caret-color: ${colors.common.blue};

    border: none;
    ${rules.borderless};

    ${(p) => p.alternate && themes.input.alternate}
    ${(p) => p.isActive && themes.input.active};
    ${(p) => p.isFocused && themes.input.focused};
    ${(p) => p.errorText && themes.input.invalid};
    ${(p) => p.disabled && themes.disabled};
  }

  > input:placeholder-shown {
    ${(p) => !p.errorText && !p.isFocused && themes.bordered};
  }

  > input::placeholder {
    opacity: 0.5;
  }

  > label {
    height: 20px;
    max-width: 101%;
    position: absolute;
    top: 19px;
    left: 0px;
    z-index: 3;

    font-family: ${fonts.alternate};
    font-size: 15px;
    line-height: 22px;
    color: ${colors.common.grey1};
    text-overflow: ellipsis;
    white-space: nowrap;

    overflow: hidden;

    ${(p) => p.isActive && themes.label.active};
    ${(p) => p.disabled && themes.disabled}
    ${(p) => p.errorText && themes.label.invalid}
  }
`;

export const AdditionalInfo = styled.div`
  margin-top: 4px;

  font-size: 10px;
  line-height: 12px;
  color: ${colors.common.grey4};
`;
