import styled from 'styled-components';
import { colors } from '../../Constants/css';

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QRCode = styled.div`
  margin-bottom: 16px;
  width: 132px;
  height: 132px;
  background-image: url(${(p) => p.image});
  background-size: 100%;
`;

export const QRCodeWrapper = styled.div`
  height: 228px;

  margin-top: 16px;
  padding: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: ${colors.common.grey};
  border-radius: 32px;
`;

export const QRCodeText = styled.div`
  text-align: center;
  font-size: 12px;
  color: ${colors.common.grey1};
`;

export const MobileButtonBox = styled.div`
  display: flex;
  margin-top: 24px;
`;

export const MobileText = styled.div`
  margin-top: 8px;
`;

export const Wrapper = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  bottom: 0;
  left: 1px;
  right: 1px;
  z-index: 1001;
  padding: 20px 15px;
  box-sizing: border-box;

  height: 99%;
  max-width: 100vw;

  background: #fff;
  color: #000;
  box-shadow: 0 0 10px -5px #000;
  border-radius: 25px 25px 0 0;
  font-weight: 700;
  font-size: 13px;
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 7px;

  font-weight: 400;
`;

export const HeaderImage = styled.img`
  max-width: 100px;
  margin-bottom: 5px;
  border-radius: 10px;
`;

export const CloseButton = styled.div`
  width: 25px;
  height: 25px;

  position: absolute;
  right: 20px;
  content: '';

  display: flex;
  justify-content: center;
  align-items: center;

  background: #e4e4e4;

  font-size: 15px;
  font-family: serif;
  border-radius: 50%;
  color: rgba(72, 72, 72, 0.6);
  cursor: pointer;
`;

export const Text = styled.div`
  max-width: 240px;
  text-align: center;
  line-height: 16px;
  font-weight: 400;
  margin: 0 auto 7px;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 15px 8px;
  margin-bottom: 10px;
  box-sizing: border-box;

  outline: 0;
  background-color: #f4f4f4;
  border: none;
  border-radius: 8px;
`;

export const ListItem = styled.a`
  min-height: 60px;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;

  background: #f4f4f4;
  border-radius: 15px;
  color: #000;
`;

export const BankImage = styled.img`
  margin-right: 10px;
  max-width: 50px;
  border-radius: 10px;
`;

export const NotFound = styled.div`
  padding: 10px;
`;
