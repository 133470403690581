/**
 * 'Option's Styled Components
 *
 * @flow
 */
import styled from 'styled-components';
import { colors } from '../../../../Constants/css';

export const Wrapper = styled.span`
  display: flex;
  justify-content: space-between;
  column-gap: 4px;

  padding: 9px 4px;

  border-radius: 8px;
  background: ${(p) => (p.isFocused ? colors.common.grey2 : 'inherit')};
  color: ${colors.common.black}

  cursor: default;
`;

export const ChildrenWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
