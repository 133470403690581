/**
 * 'Button's Styled Components
 *
 * @flow
 */
import styled, { css } from 'styled-components';
import { fonts, rules, colors, breakpoints } from '../../../Constants/css';

const { bg } = colors;

const disabledRule = css`
  background-color: ${bg.disabled};
  color: #bbbec6;
`;

const alternateDisabledRule = css`
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
`;

const disabled = css`
  ${disabledRule};

  cursor: default;

  :hover {
    ${disabledRule};
  }
`;

const alternateDisabled = css`
  ${alternateDisabledRule};

  cursor: default;

  :hover {
    ${alternateDisabledRule}
  }
`;

const black = css`
  background-color: #1f2229;
  color: #fff;

  :hover {
    background-color: #626469;
  }
`;

const darkblue = css`
  background-color: ${colors.common.black};
  color: #fff;
`;

const ghost = css`
  background-color: ${colors.common.grey};
  color: ${colors.common.black};

  :hover {
    background-color: ${colors.common.grey2};
  }
`;

const h32 = css`
  min-height: 32px;

  padding: 2px 12px;
  border-radius: 8px;

  font-size: 12px;
`;

const h52 = css`
  min-height: 52px;

  padding: 0px 24px;
  border-radius: 12px;

  font-size: 16px;
`;

export const Wrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  outline: none;

  flex:1;
  flex-basis: auto; //Fix IE11

  font-family: ${fonts.main};
  font-size: 16px;
  font-weight: bold;
  line-height: 1.88;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s

  cursor: pointer;

  ${h32};
  ${rules.borderless};

  ${(p) => (p.alternate ? darkblue : black)};
  ${(p) => p.disabled && (p.alternate ? alternateDisabled : disabled)};
  ${(p) => p.ghost && ghost};
  ${(p) => p.big && h52};

  @media (min-width: ${breakpoints.tablet}) {
    ${(p) => p.width && `width: ${p.width}`};
  }
`;
