/**
 * 'Layout's Styled Components
 *
 * @flow
 */
import styled from 'styled-components';
import { breakpoints } from '../../../Constants/css';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100vh;
`;

export const Container = styled.div`
  max-width: 351px;

  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  padding: 12px;

  @media (min-width: ${breakpoints.tablet}) {
    max-width: 752px;
    padding: 24px;
  }
`;

export const Header = styled.div`
  margin: 16px 0;

  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.titleCentered ? 'center' : 'space-between')};
  align-items: center;
  column-gap: 8px;

  @media (min-width: ${breakpoints.tablet}) {
    margin: 24px 0;
  }
`;

export const CloseIcon = styled.img`
  padding: 4px;
  cursor: pointer;

  :hover {
    color: #444852;
  }
`;
