//
// @flow
//
import type {
  TPayment3dsDataRequest,
  TPayment3dsDataResponse,
  TPaymentFrameDataRequest,
  TPaymentFrameDataResponse,
  TPaymentGetBranchesResponse,
  TPaymentGetCardRequest,
  TPaymentGetCardResponse,
  TPaymentConfirmFrameThreeDSRequest,
  TPaymentConfirmFrameThreeDSResponse,
  TPaymentGetSBPDeepLink,
  TPaymentGetSBPDeepLinkResponse,
  TPaymentInfoRequest,
  TPaymentInfoResponse,
  TPaymentLoopDataRequest,
  TPaymentLoopDataResponse,
  TPaymentPrepareLoopRequest,
  TPaymentPrepareLoopResponse,
  TPaymentPrepareNotConfirmedRequest,
  TPaymentPrepareRequest,
  TPaymentPrepareResponse,
  TPaymentPrepareSBP,
  TPaymentPrepareSBPResponse,
  TPaymentPrepareSubscriberAccountRequest,
  TPaymentPrepareThreeDSRequest,
  TPaymentPrepareThreeDSResponse,
  TPaymentStatusRequest,
  TPaymentStatusResponse,
} from '../../Constants/types';
import { api, parseApiResponse } from '../common';
import { browserInfoHeaders, defaultChannel } from '../headers';

const ROOT = `/payments`;

/**
 * Отправка данных о будущем платеже для подготовки к проверке 3dsecure
 *
 * doc: https://confluence.tyme.ru/pages/viewpage.action?pageId=43451032
 */
export const prepareThreeDS = async function (body: TPaymentPrepareRequest, headers): Promise<TPaymentPrepareResponse> {
  const response = await api.post(`${ROOT}/3ds/prepare`, body, {
    headers: { ...browserInfoHeaders, ...headers, channel: headers.channel || defaultChannel },
  });

  return parseApiResponse(response);
};

export const prepareThreeDSNonCaptcha = async function (
  body: TPaymentPrepareRequest
): Promise<TPaymentPrepareResponse> {
  const response = await api.post(`${ROOT}/3ds/prepareNonCaptcha`, body, { headers: browserInfoHeaders });

  return parseApiResponse(response);
};

export const prepareThreeDSSubscriberAccount = async function (
  body: TPaymentPrepareSubscriberAccountRequest
): Promise<TPaymentPrepareResponse> {
  const response = await api.post(`${ROOT}/3ds/subscriberAccount`, body, { headers: browserInfoHeaders });

  return parseApiResponse(response);
};

export const prepareThreeDSNotConfirmed = async function (
  body: TPaymentPrepareNotConfirmedRequest,
  headers
): Promise<TPaymentPrepareResponse> {
  const response = await api.post(`${ROOT}/3ds/prepare/defaultNotConfirmed`, body, {
    headers: { ...browserInfoHeaders, ...headers },
  });

  return parseApiResponse(response);
};

/**
 * Получение статуса платежа по его идентификатору
 *
 * doc: https://confluence.tyme.ru/pages/viewpage.action?pageId=45323487
 */
export const getPaymentStatus = async function (
  { paymentId }: TPaymentStatusRequest,
  cancelToken
): Promise<TPaymentStatusResponse> {
  const response = await api.get(`${ROOT}/${paymentId}/status`, {}, { cancelToken });

  return parseApiResponse(response);
};

/**
 * Получение данных платежа по его идентификатору
 *
 * doc: https://confluence.tyme.ru/pages/viewpage.action?pageId=44074594
 */
export const getPaymentInfo = async function (body: TPaymentInfoRequest): Promise<TPaymentInfoResponse> {
  const { paymentId } = body;
  const response = await api.get(`${ROOT}/${paymentId}`);

  return parseApiResponse(response);
};

/**
 * Получение данных о 3ds по id платежа
 *
 * doc :https://confluence.tyme.ru/pages/viewpage.action?pageId=45323500
 */
export const getThreeDS = async function (body: TPayment3dsDataRequest): Promise<TPayment3dsDataResponse> {
  const { paymentId } = body;
  const response = await api.get(`${ROOT}/${paymentId}/3ds-data`);

  return parseApiResponse(response);
};

/**
 * Получение данных о Frame по id платежа
 *
 *
 */
export const getFrame = async function (body: TPaymentFrameDataRequest): Promise<TPaymentFrameDataResponse> {
  const { paymentId } = body;
  const response = await api.get(`${ROOT}/${paymentId}/frame-data`);

  return parseApiResponse(response);
};

/**
 * Получение данных о Loop по id платежа
 *
 * doc: https://confluence.tyme.ru/pages/viewpage.action?pageId=45323533
 */
export const getLoop = async function (body: TPaymentLoopDataRequest): Promise<TPaymentLoopDataResponse> {
  const { paymentId } = body;
  const response = await api.get(`${ROOT}/${paymentId}/loop-data`);

  return parseApiResponse(response);
};

/**
 * Получение карты из платежа
 *
 * doc: https://confluence.tyme.ru/pages/viewpage.action?pageId=45323062
 */
export const getCard = async function (requestData: TPaymentGetCardRequest): Promise<TPaymentGetCardResponse> {
  const { paymentId } = requestData;

  const response = await api.get(`${ROOT}/${paymentId}/card`);

  return parseApiResponse(response);
};

/**
 * Подготовка платежа по 3ds
 *
 * doc: https://confluence.tyme.ru/pages/viewpage.action?pageId=43452483
 */
export const prepare3ds = async function (
  requestData: TPaymentPrepareThreeDSRequest,
  headers = {}
): Promise<TPaymentPrepareThreeDSResponse> {
  const response = await api.post(`${ROOT}/3ds/prepare/default`, requestData, {
    headers: { ...browserInfoHeaders, ...headers },
  });

  return parseApiResponse(response);
};

/**
 * Подготовка платежа по Loop
 *
 * doc: https://confluence.tyme.ru/pages/viewpage.action?pageId=45325799
 */
export const prepareLoop = async function (
  requestData: TPaymentPrepareLoopRequest
): Promise<TPaymentPrepareLoopResponse> {
  const response = await api.post(`${ROOT}/loop/prepare/default`, requestData);

  return parseApiResponse(response);
};

/**
 * Подготовка платежа по Системе быстрых платежей (СБП)
 *
 * doc: https://confluence.paysystem.tech/pages/viewpage.action?pageId=58492138
 */

export const prepareSBP = async function (requestData: TPaymentPrepareSBP): Promise<TPaymentPrepareSBPResponse> {
  const response = await api.post(`${ROOT}/external/sbp/prepare`, requestData);

  return parseApiResponse(response);
};

export const prepareSBPNonCaptcha = async function (
  requestData: TPaymentPrepareSBP
): Promise<TPaymentPrepareSBPResponse> {
  const response = await api.post(`${ROOT}/external/sbp/prepareNonCaptcha`, requestData);

  return parseApiResponse(response);
};

export const prepareSBPPayToken = async function (requestData, headers) {
  const response = await api.post(`${ROOT}/external/sbpPayToken/prepare`, requestData, {
    headers: { ...headers, channel: headers.channel || defaultChannel },
  });

  return parseApiResponse(response);
};

export const getSBPDeepLink = async function (
  requestData: TPaymentGetSBPDeepLink
): Promise<TPaymentGetSBPDeepLinkResponse> {
  const { paymentId } = requestData;

  const response = await api.get(`${ROOT}/external/sbp/${paymentId}/deeplink`);

  return parseApiResponse(response);
};

export const getBranches = async function (): Promise<TPaymentGetBranchesResponse> {
  const response = await api.get(`/branches`);

  return parseApiResponse(response);
};

export const confirmFrameThreeDS = async function (
  requestData: TPaymentConfirmFrameThreeDSRequest
): Promise<TPaymentConfirmFrameThreeDSResponse> {
  const { paymentId, threeDSCompInd } = requestData;

  const response = await api.post(`${ROOT}/3ds/frameConfirm/${paymentId}/${threeDSCompInd}`);

  return parseApiResponse(response);
};

export const prepare3dsHold = async function (requestData, headers): Promise<*> {
  const response = await api.post(`${ROOT}/selfregistration/3ds/hold`, requestData, {
    headers: { ...browserInfoHeaders, ...headers },
  });

  return parseApiResponse(response);
};

export const getSelfRegistrationOrder = async function (requestData): Promise<*> {
  const { payId } = requestData;
  const response = await api.get(`${ROOT}/selfRegistrationOrder/${payId}`);

  return parseApiResponse(response);
};

export const prepareSberPay = async function (requestData, headers): Promise<*> {
  const response = await api.post(`${ROOT}/external/sberpay/prepare`, requestData, {
    headers: { ...headers, channel: headers.channel || defaultChannel },
  });

  return parseApiResponse(response);
};
