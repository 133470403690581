/**
 * withCssReset HOC
 *
 * @flow
 */
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  * {
    /* margin: 0;
    padding: 0; */
    box-sizing: border-box;
  }

  html,
  body {
    height: 100%;
  }

  input::-ms-clear {
    display: none !important;
  }
`;

export default function withCssReset(Component: React$ElementType) {
  return function withCssResetHOC(props: *) {
    return (
      <Wrapper>
        <Component {...props} />
      </Wrapper>
    );
  };
}
