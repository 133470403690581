import { Form } from 'formik';
import React from 'react';

import CardFields from '../../../../Components/Blocks/CardFields';
import { Content, Wrapper, GrayBlock, BlockTitle } from '../../../../Components/Blocks/Common';
import { Wrapper as DetailWrapper } from '../../../../Components/Blocks/DetailsPanel';
import { FormContainer, Row } from '../../../../Components/Blocks/Form';
import Panel from '../../../../Components/Blocks/Panel';
import SavePayButton from '../../../../Components/Blocks/SavePayButton';
import SecureBadges from '../../../../Components/Blocks/SecureBadges';
import Button from '../../../../Components/Controls/Button';
import ReceiptElectron from '../../../../Components/Controls/ReceiptElectron';
import ErrorPanel from '../../../../Components/ErrorPanel';
import Oferta from '../../../../Components/Blocks/Oferta';

const SelfRegistrationForm = (props) => {
  const { commonError, setFieldValue, isMobile, isValid } = props;

  const isDisabledButton = !isValid;

  return (
    <Wrapper>
      <Form>
        {commonError && (
          <div style={{ margin: '0px 0px 25px 0px' }}>
            <ErrorPanel text={commonError} />
          </div>
        )}
        <Content>
          <Panel width="351px">
            <FormContainer>
              <BlockTitle style={{ marginTop: 0 }}>Реквизиты карты</BlockTitle>
              <GrayBlock>
                <CardFields setFieldValue={setFieldValue} />
              </GrayBlock>

              <BlockTitle>Куда отправить электронный чек?</BlockTitle>
              <Row>
                <ReceiptElectron
                  type={props.values.electronReceiptType}
                  emailInputName={'electronReceiptEmail'}
                  mobileInputName={'electronReceiptMobile'}
                  receiptTypeRadioName={'electronReceiptType'}
                />
              </Row>
            </FormContainer>
          </Panel>

          <Panel alternate>
            <DetailWrapper>
              <SavePayButton>
                <Button
                  label={'Оплатить картой'}
                  type={'submit'}
                  disabled={isDisabledButton}
                  big={isMobile}
                  alternate
                />
              </SavePayButton>
              <Oferta buttonLabel="Оплатить картой" />
              <SecureBadges />
            </DetailWrapper>
          </Panel>
        </Content>
      </Form>
    </Wrapper>
  );
};

export default React.memo(SelfRegistrationForm);
