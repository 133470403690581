/**
 * 'Field's Styled Components
 *
 * @flow
 */
import styled from 'styled-components';
import { colors } from '../../../../Constants/css';

export const Wrapper = styled.div`
  flex: 1;
  flex-basis: auto; //Fix IE11
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  padding: 8px 0px;
`;

export const Label = styled.div`
  font-size: 12px;
  color: ${colors.common.grey1};
  line-height: 16px;
`;

export const Value = styled.div`
  font-size: 15px;
  color: ${colors.common.black};
  line-height: 22px;
`;
