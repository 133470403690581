/**
 *
 * @flow
 */
import styled from 'styled-components';
import { colors } from '../../../Constants/css';

export const TabButton = styled.div`
  min-height: 52px;
  display: inline-flex;
  column-gap: 8px;

  align-items: center;
  word-break: normal;
  will-change: border-color;
  cursor: pointer;
  transition: 0.4s;
`;

export const Label = styled.div`
  margin-right: 5px;
  font-size: 15px;
`;

export const Image = styled.div`
  width: 56px;
  height: 56px;
  background-color: ${colors.common.grey};
  border-radius: 5px;
  background-image: url(${(p) => p.src});
  background-repeat: no-repeat;
  background-position: center;
`;

export const LabelContainer = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;
