import React, { useState } from 'react';
import TextInput from '../TextInput/FieldTextInput';
import { ElectronReceipt, ElectronReceiptInput } from './styled';
import { colors } from '../../../Constants/css';
import FieldSelect from '../Select/FieldSelect';

const FieldElectronReceiptButton = (props) => {
  const onFocus = (event) => {
    event.stopPropagation();
  };

  return (
    <FieldSelect
      onFocus={onFocus}
      name={props.name}
      style={{ width: 144, height: 48, backgroundColor: colors.common.grey2 }}
      options={[
        { label: 'На email', value: 'email' },
        { label: 'На телефон', value: 'mobile' },
      ]}
    />
  );
};

const ReceiptElectron = (props) => {
  const { hideButton } = props;

  const [focused, setFocused] = useState(false);

  const emailLabel = focused ? 'Ваш e-mail' : 'example@mail.ru';
  const mobileLabel = focused ? 'Ваш номер телефона' : 'Номер телефона';

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = (event) => {
    if (!event.target.value) {
      setFocused(false);
    }
  };

  return (
    <>
      <ElectronReceipt>
        <ElectronReceiptInput>
          {props.type === 'email' && (
            <TextInput
              prefix={hideButton ? null : <FieldElectronReceiptButton name={props.receiptTypeRadioName} />}
              type={'email'}
              name={props.emailInputName}
              label={emailLabel}
              required
              onFocus={onFocus}
              onBlur={onBlur}
            />
          )}
          {props.type === 'mobile' && (
            <TextInput
              prefix={hideButton ? null : <FieldElectronReceiptButton name={props.receiptTypeRadioName} />}
              type={'tel'}
              maskType={'phoneFull'}
              name={props.mobileInputName}
              label={mobileLabel}
              required
              onFocus={onFocus}
              onBlur={onBlur}
            />
          )}
        </ElectronReceiptInput>
      </ElectronReceipt>
    </>
  );
};

export default ReceiptElectron;
