/**
 * 'Panel's Styled Components
 *
 * @flow
 */
import styled, { css } from 'styled-components';
import { breakpoints, rules } from '../../../Constants/css';

// rules
const themes = {
  alternate: css`
    min-width: 235px;

    @media (max-width: ${breakpoints.tablet}) {
      margin-top: 24px;
      padding-bottom: 32px;
    }

    @media (min-width: ${breakpoints.tablet}) {
      width: 284px;
      margin-top: 0;
    }
  `,
};

export const Wrapper = styled.div`
  @media (min-width: ${breakpoints.tablet}) {
    ${(p) => p.width && `width: ${p.width};`}
  }

  flex: 1;
  flex-basis: auto; //Fix IE11
  flex-basis: auto; //IE11 fix

  background-color: #fff;

  @media (max-width: ${breakpoints.tablet}) {
    ${rules.borderless};
  }

  ${(p) => p.alternate && themes.alternate};
`;
