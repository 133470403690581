//
// @flow
//
import {
  getBranches,
  getCard,
  getFrame,
  getLoop,
  getPaymentInfo,
  getPaymentStatus,
  getSBPDeepLink,
  getThreeDS,
  confirmFrameThreeDS,
  prepare3ds,
  prepareLoop,
  prepareSBP,
  prepareSBPNonCaptcha,
  prepareThreeDS,
  prepareThreeDSNonCaptcha,
  prepareThreeDSNotConfirmed,
  prepareThreeDSSubscriberAccount,
  prepare3dsHold,
  getSelfRegistrationOrder,
  prepareSberPay,
  prepareSBPPayToken,
} from '../Api/Payments';
import { getSbpBindStatus } from '../Api/SbpTokens';
import { ValidationError } from '../Constants/errors';
import type {
  TPayment3dsDataRequest,
  TPayment3dsDataResponse,
  TPaymentFrameDataRequest,
  TPaymentFrameDataResponse,
  TPaymentGetBranchesResponse,
  TPaymentGetCardRequest,
  TPaymentGetCardResponse,
  TPaymentGetSBPDeepLink,
  TPaymentGetSBPDeepLinkResponse,
  TPaymentConfirmFrameThreeDSRequest,
  TPaymentConfirmFrameThreeDSResponse,
  TPaymentInfoResponse,
  TPaymentLoopDataRequest,
  TPaymentLoopDataResponse,
  TPaymentPrepareLoopResponse,
  TPaymentPrepareNotConfirmedRaw,
  TPaymentPreparePaymentRawRequest,
  TPaymentPrepareRaw,
  TPaymentPrepareResponse,
  TPaymentPrepareSBP,
  TPaymentPrepareSBPResponse,
  TPaymentPrepareSubscriberAccountRaw,
  TPaymentPrepareThreeDSRawRequest,
  TPaymentPrepareThreeDSResponse,
  TPaymentStatusRequest,
  TPaymentStatusResponse,
} from '../Constants/types';
import { pollStatus } from '../Helpers';
import { convertDecimalRublesToKopecks, convertIntegerRublesToKopecks } from '../Helpers/Currency';
import {
  normalizeMsisdnToElevenNumbers,
  normalizeMsisdnToTenNumbers,
  normalizePan,
  sliceExpirationDate,
} from '../Helpers/Normalize';

export default class PaymentService {
  static async prepareThreeDS(
    payment: TPaymentPrepareRaw,
    isMobile: boolean,
    headers
  ): Promise<TPaymentPrepareResponse> {
    const { msisdn, amount, pan, expirationDate, cvv, receipt, callbackRoute } = payment;
    const { expirationMonth: month, expirationYear: year } = sliceExpirationDate(expirationDate);

    const body = {
      msisdn: normalizeMsisdnToElevenNumbers(msisdn),
      amount: convertIntegerRublesToKopecks(amount),
      pan: normalizePan(pan),
      expirationDate: {
        month,
        year,
      },
      cvv,
      receive: {
        email: receipt.email,
        phoneNumber: receipt.mobile ? normalizeMsisdnToElevenNumbers(receipt.mobile) : receipt.mobile,
      },
      callbackRoute,
    };

    try {
      if (isMobile) {
        return await prepareThreeDSNonCaptcha(body);
      } else {
        return await prepareThreeDS(body, headers);
      }
    } catch (error) {
      // TODO: Вынести в отдельный общий метод, т.к. Сабскрайбер с методом addCardToSubscriber использует аналогичный инжект
      // этот метод актуельнне аналогичного в сабскрайбере
      if (error instanceof ValidationError) {
        const { fieldErrors } = error;

        if (fieldErrors && (fieldErrors['expirationDate.Month'] || fieldErrors['expirationDate.Year'])) {
          error.fieldErrors.expirationDate = fieldErrors['expirationDate.Month'] || fieldErrors['expirationDate.Year'];
        }

        if (fieldErrors && fieldErrors['receive.Email']) {
          error.fieldErrors.electronReceiptEmail = fieldErrors['receive.Email'];
        }
        if (fieldErrors && fieldErrors['receive.PhoneNumber']) {
          error.fieldErrors.electronReceiptMobile = fieldErrors['receive.PhoneNumber'];
        }
      }

      throw error;
    }
  }

  static async prepareThreeDSSubscriberAccount(
    payment: TPaymentPrepareSubscriberAccountRaw
  ): Promise<TPaymentPrepareResponse> {
    const { subscriberAccount, branchId, amount, pan, expirationDate, cvv, receipt, callbackRoute } = payment;
    const { expirationMonth: month, expirationYear: year } = sliceExpirationDate(expirationDate);

    try {
      return await prepareThreeDSSubscriberAccount({
        subscriberAccount: subscriberAccount,
        branchId: branchId,
        amount: convertDecimalRublesToKopecks(amount),
        pan: normalizePan(pan),
        expirationDate: {
          month,
          year,
        },
        cvv,
        receive: {
          email: receipt.email,
          phoneNumber: receipt.mobile ? normalizeMsisdnToElevenNumbers(receipt.mobile) : receipt.mobile,
        },
        callbackRoute,
      });
    } catch (error) {
      // TODO: Вынести в отдельный общий метод, т.к. Сабскрайбер с методом addCardToSubscriber использует аналогичный инжект
      // этот метод актуельнне аналогичного в сабскрайбере
      if (error instanceof ValidationError) {
        const { fieldErrors } = error;

        if (fieldErrors && (fieldErrors['expirationDate.Month'] || fieldErrors['expirationDate.Year'])) {
          error.fieldErrors.expirationDate = fieldErrors['expirationDate.Month'] || fieldErrors['expirationDate.Year'];
        }

        if (fieldErrors && fieldErrors['receive.Email']) {
          error.fieldErrors.electronReceiptEmail = fieldErrors['receive.Email'];
        }
        if (fieldErrors && fieldErrors['receive.PhoneNumber']) {
          error.fieldErrors.electronReceiptMobile = fieldErrors['receive.PhoneNumber'];
        }
      }

      throw error;
    }
  }

  static async prepareThreeDSNotConfirmed(payment: TPaymentPrepareNotConfirmedRaw): Promise<TPaymentPrepareResponse> {
    const { msisdn, pan, expirationDate, cvv, receipt, callbackRoute } = payment;
    const { expirationMonth: month, expirationYear: year } = sliceExpirationDate(expirationDate);

    try {
      return await prepareThreeDSNotConfirmed({
        msisdn: normalizeMsisdnToElevenNumbers(msisdn),
        pan: normalizePan(pan),
        expirationDate: {
          month,
          year,
        },
        cvv,
        receive: {
          email: receipt.email,
          phoneNumber: receipt.mobile ? normalizeMsisdnToElevenNumbers(receipt.mobile) : receipt.mobile,
        },
        callbackRoute,
      });
    } catch (error) {
      // TODO: Вынести в отдельный общий метод, т.к. Сабскрайбер с методом addCardToSubscriber использует аналогичный инжект
      // этот метод актуельнне аналогичного в сабскрайбере
      if (error instanceof ValidationError) {
        const { fieldErrors } = error;

        if (fieldErrors && (fieldErrors['expirationDate.Month'] || fieldErrors['expirationDate.Year'])) {
          error.fieldErrors.expirationDate = fieldErrors['expirationDate.Month'] || fieldErrors['expirationDate.Year'];
        }

        if (fieldErrors && fieldErrors['receive.Email']) {
          error.fieldErrors.electronReceiptEmail = fieldErrors['receive.Email'];
        }
        if (fieldErrors && fieldErrors['receive.PhoneNumber']) {
          error.fieldErrors.electronReceiptMobile = fieldErrors['receive.PhoneNumber'];
        }
      }

      throw error;
    }
  }

  static async getPaymentInfo({ paymentId }: { paymentId: string }): Promise<TPaymentInfoResponse> {
    const payment = await getPaymentInfo({ paymentId });
    return { ...payment, msisdn: normalizeMsisdnToTenNumbers(payment.msisdn) };
  }

  /**
   * Опрашивает платеж до тех пор, пока не получит один из ожидаемых статусов или не отвалится по кол-ву попыток
   *
   */
  static async getPaymentExactStatus(requestData: {
    paymentId: string,
    expectedStatuses: Array<string>,
    tries?: number,
    timeout?: number,
    cancelToken?: *,
  }): Promise<{
    status: string,
    reason?: { name: string, description: string },
    isLoopEnabled: boolean,
    backLink: string,
  }> {
    const { paymentId, cancelToken, ...config } = requestData;

    try {
      const payment = await pollStatus(() => getPaymentStatus({ paymentId }, cancelToken), config);
      const { status, reason, isLoopEnabled = false, backLink } = payment || {};

      return { status, reason, isLoopEnabled, backLink };
    } catch (e) {
      throw e;
    }
  }

  static async getPaymentStatus(requestData: TPaymentStatusRequest, cancelToken): Promise<TPaymentStatusResponse> {
    return await getPaymentStatus(requestData, cancelToken);
  }

  static async getThreeDS(requestData: TPayment3dsDataRequest): Promise<TPayment3dsDataResponse> {
    return await getThreeDS(requestData);
  }

  static async getFrame(requestData: TPaymentFrameDataRequest): Promise<TPaymentFrameDataResponse> {
    return await getFrame(requestData);
  }

  static async getLoop(requestData: TPaymentLoopDataRequest): Promise<TPaymentLoopDataResponse> {
    return await getLoop(requestData);
  }

  static async getCard(requestData: TPaymentGetCardRequest): Promise<TPaymentGetCardResponse> {
    return await getCard(requestData);
  }

  static async confirmFrameThreeDS(
    requestData: TPaymentConfirmFrameThreeDSRequest
  ): Promise<TPaymentConfirmFrameThreeDSResponse> {
    return await confirmFrameThreeDS(requestData);
  }

  static async getCardLoop(requestData: TPaymentGetCardRequest): Promise<TPaymentGetCardResponse> {
    const MAX_COUNT = 3;
    let count = 1;
    let lastError;

    while (count <= MAX_COUNT) {
      try {
        return await getCard(requestData);
      } catch (error) {
        count++;
        lastError = error;
      }

      await new Promise((next) => setTimeout(next, 1000));
    }

    throw lastError;
  }

  static async prepare3ds(payment: TPaymentPrepareThreeDSRawRequest, headers): Promise<TPaymentPrepareThreeDSResponse> {
    try {
      const { subscriberId, msisdn, pan, expirationDate, cvv, receipt, callbackRoute } = payment;
      const { expirationMonth: month, expirationYear: year } = sliceExpirationDate(expirationDate);

      return await prepare3ds(
        {
          subscriberId: subscriberId,
          msisdn: normalizeMsisdnToElevenNumbers(msisdn),
          pan: normalizePan(pan),
          expirationDate: {
            month,
            year,
          },
          cvv,
          receive: {
            email: receipt.email,
            phoneNumber: receipt.mobile ? normalizeMsisdnToElevenNumbers(receipt.mobile) : receipt.mobile,
          },
          callbackRoute,
        },
        headers
      );
    } catch (error) {
      // TODO: Вынести в отдельный общий метод, т.к. Сабскрайбер с методом addCardToSubscriber использует аналогичный инжект
      // этот метод актуельнне аналогичного в сабскрайбере
      if (error instanceof ValidationError) {
        const { fieldErrors } = error;

        if (fieldErrors && (fieldErrors['expirationDate.Month'] || fieldErrors['expirationDate.Year'])) {
          error.fieldErrors.expirationDate = fieldErrors['expirationDate.Month'] || fieldErrors['expirationDate.Year'];
        }

        if (fieldErrors && fieldErrors['receive.Email']) {
          error.fieldErrors.electronReceiptEmail = fieldErrors['receive.Email'];
        }
        if (fieldErrors && fieldErrors['receive.PhoneNumber']) {
          error.fieldErrors.electronReceiptMobile = fieldErrors['receive.PhoneNumber'];
        }
      }

      throw error;
    }
  }

  static async prepareLoop(payment: TPaymentPreparePaymentRawRequest): Promise<TPaymentPrepareLoopResponse> {
    try {
      const { msisdn, pan, expirationDate, cvv, receipt } = payment;
      const { expirationMonth: month, expirationYear: year } = sliceExpirationDate(expirationDate);

      return await prepareLoop({
        msisdn: normalizeMsisdnToElevenNumbers(msisdn),
        pan: normalizePan(pan),
        expirationDate: {
          month,
          year,
        },
        cvv,
        receive: {
          email: receipt.email,
          phoneNumber: receipt.mobile ? normalizeMsisdnToElevenNumbers(receipt.mobile) : receipt.mobile,
        },
      });
    } catch (error) {
      // TODO: Вынести в отдельный общий метод, т.к. Сабскрайбер с методом addCardToSubscriber использует аналогичный инжект
      // этот метод актуельнне аналогичного в сабскрайбере
      if (error instanceof ValidationError) {
        const { fieldErrors } = error;

        if (fieldErrors && (fieldErrors['expirationDate.Month'] || fieldErrors['expirationDate.Year'])) {
          error.fieldErrors.expirationDate = fieldErrors['expirationDate.Month'] || fieldErrors['expirationDate.Year'];
        }

        if (fieldErrors && fieldErrors['receive.Email']) {
          error.fieldErrors.electronReceiptEmail = fieldErrors['receive.Email'];
        }
        if (fieldErrors && fieldErrors['receive.PhoneNumber']) {
          error.fieldErrors.electronReceiptMobile = fieldErrors['receive.PhoneNumber'];
        }
      }

      throw error;
    }
  }

  static async prepareSBP(payment: TPaymentPrepareSBP, isMobile: boolean): Promise<TPaymentPrepareSBPResponse> {
    const { msisdn, amount } = payment;

    const body = {
      msisdn: normalizeMsisdnToElevenNumbers(msisdn),
      amount: convertIntegerRublesToKopecks(amount),
    };

    try {
      if (isMobile) {
        return await prepareSBPNonCaptcha(body);
      } else {
        return await prepareSBP(body);
      }
    } catch (error) {
      throw error;
    }
  }

  static prepareSBPPayToken(payment, headers): Promise<*> {
    const { msisdn, amount, paymentId } = payment;

    const body = {
      msisdn: normalizeMsisdnToElevenNumbers(msisdn),
      amount: convertIntegerRublesToKopecks(amount),
      paymentId,
    };

    return prepareSBPPayToken(body, headers);
  }

  static async getSBPDeepLink(requestData: TPaymentGetSBPDeepLink): Promise<TPaymentGetSBPDeepLinkResponse> {
    const response = await getSBPDeepLink(requestData);
    return response;
  }

  static async getBranches(): Promise<TPaymentGetBranchesResponse> {
    const response = await getBranches();
    return response;
  }

  static async hold(payment: *, headers): Promise<*> {
    const { pan, expirationDate, cvv, receipt, callbackRoute, paymentId } = payment;
    const { expirationMonth: month, expirationYear: year } = sliceExpirationDate(expirationDate);

    const body = {
      pan: normalizePan(pan),
      expirationDate: {
        month,
        year,
      },
      cvv,
      receive: {
        email: receipt.email,
        phoneNumber: receipt.mobile ? normalizeMsisdnToElevenNumbers(receipt.mobile) : receipt.mobile,
      },
      callbackRoute,
      paymentId,
    };

    try {
      return await prepare3dsHold(body, headers);
    } catch (error) {
      if (error instanceof ValidationError) {
        const { fieldErrors } = error;

        if (fieldErrors && (fieldErrors['expirationDate.Month'] || fieldErrors['expirationDate.Year'])) {
          error.fieldErrors.expirationDate = fieldErrors['expirationDate.Month'] || fieldErrors['expirationDate.Year'];
        }

        if (fieldErrors && fieldErrors['receive.Email']) {
          error.fieldErrors.electronReceiptEmail = fieldErrors['receive.Email'];
        }
        if (fieldErrors && fieldErrors['receive.PhoneNumber']) {
          error.fieldErrors.electronReceiptMobile = fieldErrors['receive.PhoneNumber'];
        }
      }

      throw error;
    }
  }

  static async getSelfRegistrationOrder(requestData): Promise<*> {
    const response = await getSelfRegistrationOrder(requestData);
    return response;
  }

  static async prepareSberPay(payment: *, headers): Promise<*> {
    const { msisdn, amount } = payment;

    const body = {
      msisdn: normalizeMsisdnToElevenNumbers(msisdn),
      amount: convertIntegerRublesToKopecks(amount),
    };

    try {
      return await prepareSberPay(body, headers);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Опрашивает привязку СБП токена до тех пор, пока не получит один из ожидаемых статусов или не отвалится по кол-ву попыток
   *
   */
  static async getSbpBindExactStatus(requestData: {
    sbpTokenId: string,
    expectedStatuses: Array<string>,
    tries?: number,
    timeout?: number,
    cancelToken?: *,
  }): Promise<{
    status: string,
  }> {
    const { sbpTokenId, cancelToken, ...config } = requestData;

    const payment = await pollStatus(() => getSbpBindStatus({ sbpTokenId }, cancelToken), config);
    return payment;
  }

  static getSbpBindStatus(requestData: TPaymentStatusRequest, cancelToken): Promise<TPaymentStatusResponse> {
    return getSbpBindStatus(requestData, cancelToken);
  }
}
