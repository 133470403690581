import React from 'react';
import CardFields from '../../../../../Components/Blocks/CardFields';
import { FormContainer, Row } from '../../../../../Components/Blocks/Form';
import Panel from '../../../../../Components/Blocks/Panel';
import RadioTab from '../../../../../Components/Controls/RadioButton';
import RadioTabGroup from '../../../../../Components/Controls/RadioTabGroup';
import ReceiptElectron from '../../../../../Components/Controls/ReceiptElectron';
import { withMobile } from '../../../../../Components/HOC';
import { SBPContentWrapper } from '../styled';
import PaymentInfo from './PaymentInfo';
import SBPInfo from './SBPInfo';
import CardBind from './CardBind';
import SBPBind from './SBPBind';
import { BlockTitle, GrayBlock } from '../../../../../Components/Blocks/Common';
import AccountInfo from '../../../../../Components/Blocks/AccountInfo';

const cardLogo = '/assets/images/bank-card.svg';
const sbpLogo = '/assets/images/sbp-logo.svg';

const RequisitesPanel = (props) => {
  const {
    isCardValid,
    errors,
    setFieldValue,
    values,
    onCardBind,
    remainingQuantity,
    setPayType,
    payType,
    isMobile,
    isSbpActive,
    isCardActive,
    elementIsDisabled,
    onSbpBind,
    sbpRemainingQuantity,
  } = props;

  const cardContent = (
    <>
      <BlockTitle>Реквизиты карты</BlockTitle>
      <GrayBlock>
        <CardFields setFieldValue={setFieldValue} />
        {!elementIsDisabled && (
          <CardBind
            values={values}
            errors={errors}
            onCardBind={onCardBind}
            remainingQuantity={remainingQuantity}
            isCardValid={isCardValid}
          />
        )}
      </GrayBlock>
      <BlockTitle>Куда отправить электронный чек?</BlockTitle>
      <Row>
        <ReceiptElectron
          type={values.electronReceiptType}
          emailInputName={'electronReceiptEmail'}
          mobileInputName={'electronReceiptMobile'}
          receiptTypeRadioName={'electronReceiptType'}
          isMobile={isMobile}
        />
      </Row>
    </>
  );

  const sbpContent = (
    <SBPContentWrapper>
      <SBPBind values={values} errors={errors} onSbpBind={onSbpBind} remainingQuantity={sbpRemainingQuantity} />
      <SBPInfo />
    </SBPContentWrapper>
  );

  const renderContent = () => {
    if (isSbpActive && isCardActive) {
      return (
        <>
          <BlockTitle style={{ marginTop: '32px' }}>Способ оплаты</BlockTitle>
          <RadioTabGroup checked={payType} setChecked={setPayType}>
            <RadioTab label="Система быстрых платежей" name="sbp" image={sbpLogo} width="254px">
              {sbpContent}
            </RadioTab>
            <RadioTab label="Банковская карта" name="card" default image={cardLogo} width="254px">
              {cardContent}
            </RadioTab>
          </RadioTabGroup>
        </>
      );
    }

    if (isSbpActive && !isCardActive) {
      return sbpContent;
    }

    return cardContent;
  };

  return (
    <Panel width="351px">
      <FormContainer>
        <PaymentInfo />
        <AccountInfo />
        {renderContent()}
      </FormContainer>
    </Panel>
  );
};

export default withMobile(React.memo(RequisitesPanel));
