//
// @flow
//
import { css } from 'styled-components';

const URL = '/assets/images/';

export const breakpoints = {
  desktop: '1117px',
  tablet: '768px',
  mobile: '640px',
};

export const fonts = {
  main: 'HalvarBreit-ExtraBold',
  alternate: 'Rooftop',
};

export const colors = {
  themes: {
    main: '#000000',
    alternate: '#000000',
  },
  common: {
    grey: '#f2f2f2',
    grey1: '#808080',
    grey2: '#d9d9d9',
    grey3: '#b3b3b3',
    grey4: '#999ea8',
    black: '#000000',
    lightBlue: '#e5f7ff',
    blue: '#0000ff',
    white: '#ffffff',
    lightRed: '#ffe5e9',
    red: '#b31831',
  },
  text: {
    main: '#000000',
    disabled: '#a2abb4',
  },
  fail: '#d81b3b',
  bg: {
    disabled: '#f2f3f4',
  },
  border: {
    main: '#000',
    second: '#d9d9d9',
    disabled: '#a7abaf',
    normal: '#d3d9dF',
    hover: '#a2abb4',
  },
};

export const images = {
  checkbox: `${URL}checkbox.svg`,
  titleGreen: `${URL}title-green2.gif`,
  correctInput: `${URL}correct_input.png`,
  failInput: `${URL}fail_input.svg`,
  paySave: `${URL}pay-save.svg`,
  bgOpener: `${URL}bg-opener.png`,
  mirCard: `${URL}mir-logo.svg`,
  maestroCard: `${URL}maestro.svg`,
  masterVisaCard: `${URL}master_visa_card.png`,
  visaCard: `${URL}visa-inc-logo.svg`,
  masterCard: `${URL}mc-vrt-pos.svg`,
  visaSecure: `${URL}visa-secure.svg`,
  masterCardSecure: `${URL}mastercard-secure.svg`,
  mirSecure: `${URL}mir-secure.svg`,
  pciSecure: `${URL}pci-secure.svg`,
  sbpLogo: `${URL}sbp-logo.svg`,
  sbpText: `${URL}sbp-text.svg`,
  sbpLogoWithText: `${URL}sbp-logo-with-text.svg`,
  sberPayLogo: `${URL}sber-pay-logo.svg`,
  bankCard: `${URL}bank-card.svg`,
  closedEye: `${URL}closed-eye.svg`,
  openedEye: `${URL}opened-eye.svg`,
  mark: `${URL}mark.svg`,
  cross: `${URL}cross.svg`,
  calendar: `${URL}calendar.svg`,
  sadPanda: `${URL}sad-panda.svg`,
  happyPanda: `${URL}happy-panda.svg`,
};

export const rules = {
  borderless: css`
    border: 0 !important;

    :hover {
      border: 0 !important;
    }
  `,
  hidden: css`
    display: none;
  `,
};
