/**
 * Common Form layouts
 *
 * @flow
 */

import styled from 'styled-components';
import { breakpoints } from '../../../Constants/css';

export const FormContainer = styled.div`
  display: flex;

  flex-direction: column;
  align-items: stretch;

  @media (max-width: ${breakpoints.mobile}) {
    padding: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
  }
`;
