import * as React from 'react';

import { Buttons } from '../../../../../Components/Blocks/DetailsPanel';
import SavePayButton from '../../../../../Components/Blocks/SavePayButton';
import Button from '../../../../../Components/Controls/Button';
import { SberPayButton } from '../styled';
import SecureBadges from '../../../../../Components/Blocks/SecureBadges';
import { images } from '../../../../../Constants/css';
import Oferta from '../../../../../Components/Blocks/Oferta';

const CardInfo = (props) => {
  const label = <img src={images.sberPayLogo} height={props.isMobile ? 30 : 16} alt="SberPay лого" />;

  return (
    <>
      <SavePayButton>
        <Buttons>
          <Button
            type={'submit'}
            onClick={props.onClickCardPay}
            label={'Оплатить картой'}
            disabled={!props.isValid}
            big={props.isMobile}
            alternate
          />
          {props.isSberPayButtonVisible && (
            <SberPayButton
              label={label}
              borderless
              type={'submit'}
              onClick={props.onClickSberPay}
              disabled={!props.isSberPayValid}
              big={props.isMobile}
            />
          )}
        </Buttons>
      </SavePayButton>
      <Oferta buttonLabel="Оплатить картой" />
      <SecureBadges />
    </>
  );
};

export default React.memo(CardInfo);
