/**
 * 'TextInput', Control Component
 *
 * @flow
 */
import React from 'react';
import FieldTextInput from './FieldTextInput';
import MaskedInput from './MaskedInput';
import { AdditionalInfo, InputWrapper, Wrapper } from './styled';

export { FieldTextInput };

// prettier-ignore-next-statement
export type TProps = {
  /** Имя поля */
  name?: string,
  /** Название поля */
  label: string,
  /** Активное поле или заблокированное */
  disabled?: boolean,
  /** Значение поля */
  value?: string,
  /** Placeholder */
  placeholder?: string,
  /** Callback при изменении значения в поле */
  onChange?: (data: SyntheticInputEvent<>) => void,
  /** Callback при клике на поле */
  onClick?: Function,
  /** Обязательное поле */
  required?: boolean,
  /** Текст ошибки */
  errorText?: string,
  /** Callback при потере фокуса */
  onBlur?: (e?: SyntheticKeyboardEvent<>) => void,
  iconSize?: number,
  /** Callback при фокусe */
  onFocus?: (e?: SyntheticKeyboardEvent<>) => void,
  /** Параметры маски ввода значения */
  maskType?: string,
  /** Автовыделение */
  autoselect?: boolean,
  /** Заглавными буквами */
  uppercased?: boolean,
  /** Только латинскими буквами */
  latinCharsOnly?: boolean,
  /** Добавление 'guide' символа маски */
  guide?: boolean,
  /** Тип поля: text, tel, email etc. */
  type?: string,
  /** Признак визуальлного стиля "отсутвие рамок" */
  borderless?: boolean,
  /** Стандартный атрибут input элемента autocomplete */
  autoComplete?: string,
  /** Стандартный атрибут input элемента maxlength */
  maxLength?: string,
  /**Стандартный атрибут input элемента readonly */
  readOnly?: boolean,
  hideErrorOnFocus?: boolean,
  className?: string,
  alternate?: boolean,
};

type TState = {
  isActive: boolean,
  isFocused: boolean,
};

export default class TextInput extends React.Component<TProps, TState> {
  input: ?HTMLInputElement;

  constructor(props: TProps) {
    super(props);

    this.state = {
      isActive: false,
      isFocused: false,
    };
  }

  static getDerivedStateFromProps(props: TProps, state: TState): TState {
    const isActive =
      props.placeholder || Boolean(props.value && props.value.toString().length > 0) ? true : state.isActive;
    return {
      ...state,
      isActive,
    };
  }

  activateInput = (e?: SyntheticKeyboardEvent<>) => {
    if (this.input.disabled) return;

    this.setState({
      isActive: true,
      isFocused: true,
    });

    if (this.input) {
      this.input.focus();
    }

    if (this.props.onFocus && e) {
      this.props.onFocus(e);
    }
  };

  deactivateInput = (e?: SyntheticKeyboardEvent<>) => {
    this.setState({
      isActive: Boolean(this.input && this.input.value.length > 0),
      isFocused: false,
    });

    if (this.props.onBlur && e) {
      this.props.onBlur(e);
    }
  };

  handleClickWrapper = () => {
    if (this.input) {
      this.input.focus();
    }
  };

  componentDidMount() {
    this.deactivateInput();
  }

  render() {
    const { errorText, label, maskType, borderless, className, disabled, alternate, prefix, suffix, additionalInfo } =
      this.props;
    const styledProps = { borderless, errorText, disabled, alternate };

    const props = {
      onChange: this.props.onChange,
      onBlur: this.deactivateInput,
      onFocus: this.activateInput,
      onClick: this.props.onClick,
      disabled: this.props.disabled,
      name: this.props.name,
      type: this.props.type || 'text',
      autoComplete: this.props.autoComplete,
      maxLength: this.props.maxLength,
      readOnly: this.props.readOnly,
      placeholder: this.props.placeholder,
    };

    return (
      <>
        <Wrapper
          onClick={this.handleClickWrapper}
          {...styledProps}
          {...this.state}
          data-testid={`input-${props.name || ''}`}
          className={className}
        >
          {prefix ? prefix : null}
          <InputWrapper {...styledProps} {...this.state}>
            <label>
              {this.props.label} {!this.props.required && '(необязательно)'}
            </label>
            {maskType ? (
              <MaskedInput
                guide={!!this.props.guide}
                maskType={maskType}
                value={this.props.value || ''}
                ref={(component) => (this.input = component && component.inputElement ? component.inputElement : null)}
                {...props}
              />
            ) : (
              <input value={this.props.value || ''} ref={(input) => (this.input = input)} {...props} />
            )}
          </InputWrapper>
          {suffix ? suffix : null}
        </Wrapper>
        {additionalInfo ? <AdditionalInfo>{additionalInfo}</AdditionalInfo> : null}
      </>
    );
  }
}
