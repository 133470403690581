/**
 * 'Autopayment's Styled Components
 *
 * @flow
 */
import styled from 'styled-components';

export const BlockTitle = styled.h2`
  margin: 16px 0 12px 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
`;

export const CheckCardMessage = styled.p`
  margin-top: 12px;
  margin-bottom: 0px;

  font-size: 12px;
  line-height: 16px;
  color: #7b7b7b;
`;

export const CheckCardSum = styled.span`
  white-space: nowrap;
`;

export const BindCodeBox = styled.div`
  margin-top: 12px;
`;
