import React from 'react';
import { compose } from 'recompose';

import { withErrorsHandle, withQueryParams } from '../../Components/HOC';

import Layout from '../../Components/Blocks/Layout';
import B2bResult from './B2bResult';

class BindCardPage extends React.Component {
  render() {
    return (
      <Layout title={'Добавление карты'}>
        {this.props.queryParams.paymentid && (
          <B2bResult
            failedTopic={'Не удалось привязать карту'}
            failedMessage="Пожалуйста, попробуйте позже"
            paymentId={this.props.queryParams.paymentid}
          />
        )}
      </Layout>
    );
  }
}

export default compose(withQueryParams, withErrorsHandle)(BindCardPage);
