import styled from 'styled-components';
import { breakpoints, colors } from '../../../Constants/css';

export const Wrapper = styled.div`
  display: flex;

  flex: 1;
  flex-direction: column;
  flex-basis: auto; //Fix IE11

  > form {
    height: 100%;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: auto; //Fix IE11

  flex: 1;
  column-gap: 69px;

  @media (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
  }
`;

export const GrayBlock = styled.div`
  padding: 12px;
  background-color: ${colors.common.grey};
  border-radius: 16px;

  @media (min-width: ${breakpoints.tablet}) {
    padding: 24px;
    border-radius: 32px;
  }
`;

export const BlockTitle = styled.div`
  margin: 24px 0 12px 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
`;

export const LightBlueBlock = styled.div`
  height: fit-content;
  flex: 1;

  padding: 12px 16px;
  margin-top: 12px;

  background: ${colors.common.lightBlue};
  border-radius: 16px;

  line-height: 22px;
`;

export const Controls = styled.div`
  width: 100%;

  display: flex;
  flex: 1;
  flex-basis: auto; //Fix IE11
  flex-direction: column;
  gap: 16px;

  margin: 48px 0 0 0;

  @media (min-width: ${breakpoints.tablet}) {
    margin: 32px 0 0 0;
    width: auto;
    flex-direction: row;
  }
`;
