/**
 * 'Succeeded's Styled Components
 *
 * @flow
 */
import styled from 'styled-components';
import { breakpoints, fonts, images } from '../../../Constants/css';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;

  flex: 1;
  flex-basis: auto; //Fix IE11

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
  }
`;

export const Header = styled.div`
  display: flex;

  flex: 1;
  flex-basis: auto; //Fix IE11
  align-items: center;

  @media (max-width: ${breakpoints.mobile}) {
    width: auto;
  }
`;

export const Icon = styled.div`
  width: 24px;
  height: 24px;

  background-image: url(${images.correctInput});
  background-repeat: no-repeat;
`;

export const Label = styled.div`
  margin: 0 0 0 15px;

  font-family: ${fonts.alternate};
  font-weight: bold;
  font-size: 22px;
`;

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-basis: auto; //Fix IE11
  flex-direction: column;
  row-gap: 16px;

  margin-top: 24px;

  @media (min-width: ${breakpoints.tablet}) {
    width: fit-content;

    flex-direction: row;
    column-gap: 16px;
    row-gap: 0;

    margin-top: 32px;
  }
`;
