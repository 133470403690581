import React from 'react';
import { Wrapper } from './styled';
import { withMobile } from '../../HOC';

const Oferta = (props) => {
  const { buttonLabel, isMobile } = props;

  return (
    <Wrapper>
      Нажимая «{`${buttonLabel}`}»,
      {!isMobile && <br />} вы соглашаетесь {isMobile && <br />} с{' '}
      <a href={'https://t2.ru/payoferta'} target={'_blank noreferrer noopener'}>
        условиями предоставления услуги
      </a>
    </Wrapper>
  );
};

export default withMobile(Oferta);
