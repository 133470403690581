import React from 'react';

import SavePayButton from '../../../../../Components/Blocks/SavePayButton';
import SBPButton from '../../../../../Components/Controls/SBPButton';
import Oferta from '../../../../../Components/Blocks/Oferta';

const SBPInfo = ({ isMobile, isValid, onClickSBPPay }) => {
  return (
    <>
      <SavePayButton>
        <SBPButton type={'submit'} onClick={onClickSBPPay} disabled={!isValid} big={isMobile} />
      </SavePayButton>
      <Oferta buttonLabel="Оплатить СБП" />
    </>
  );
};

export default React.memo(SBPInfo);
