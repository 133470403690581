import React, { useContext } from 'react';
import { IsMobileContext } from '../Context/IsMobile';

const withMobile = (Component) => (props) => {
  const { isMobile } = useContext(IsMobileContext) || {};

  return <Component {...props} isMobile={isMobile} />;
};

export default withMobile;
