import React from 'react';
import { compose, withHandlers, withProps, withStateHandlers } from 'recompose';

import Succeed from '../../../../../Components/Blocks/Succeed';
import ErrorPanel from '../../../../../Components/ErrorPanel';
import { withCloseInFrame, withFetching } from '../../../../../Components/HOC';
import withFormik from '../../../../../Components/HOC/withFormikHelper';
import { schemas } from '../../../../../Helpers/validation';
import acceptCode from './Scenario/acceptCode';
import bindCard from './Scenario/bindCard';
import { Wrapper, BindCodeBox } from './styled';
import BindCode from '../../../../../Components/Blocks/BindCode';
import Button from '../../../../../Components/Controls/Button';
import { Controls } from '../../../../../Components/Blocks/Common';

const BindCardForm = (props) => {
  const { msisdn, topic, message, remainingQuantity, onBindCardClick } = props;

  return (
    <Wrapper>
      {props.commonError && (
        <div style={{ margin: '0px 0px 25px 0px' }}>
          <ErrorPanel text={props.commonError} />
        </div>
      )}
      {!props.values.isCardBind && (
        <Succeed
          {...{
            topic,
            message,
            isSubmitButtonDisabled: false,
            isSubmitButtonVisible: true,
            submitButtonLabel: 'Сохранить карту',
            onSubmit: onBindCardClick,
          }}
        />
      )}
      {props.values.isCardBind && (
        <>
          <BindCodeBox>
            <BindCode
              msisdn={msisdn}
              isBind={props.values.isCardBind}
              fieldName="acceptCode"
              remainingQuantity={remainingQuantity}
            />
          </BindCodeBox>
          <Controls>
            <Button disabled={!props.isValid} label={'Сохранить карту'} onClick={props.handleSubmit} big alternate />
            <Button label={'Закрыть'} onClick={props.onClose} ghost big />
          </Controls>
        </>
      )}
    </Wrapper>
  );
};

export default compose(
  withFetching,
  withCloseInFrame,
  withStateHandlers(
    {
      remainingQuantity: '',
      commonError: '',
    },
    {
      setCommonError: () => (commonError) => ({ commonError }),
      setRemainingQuantity: () => (remainingQuantity) => ({ remainingQuantity }),
    }
  ),
  withProps({
    values: { isCardBind: false, acceptCode: '' },
  }),
  withFormik({ handleSubmit: bindCard }, schemas),
  withHandlers({
    onBindCardClick: (props) => () => {
      acceptCode(props);
    },
  })
)(React.memo(BindCardForm));
