/**
 * 'Failed'
 *
 * @flow
 */
import React from 'react';

import Button from '../../Controls/Button';
import { Message, Topic, Wrapper } from './styled';
import { Controls } from '../Common';

type TProps = {
  /** Заголовок сообщения */
  topic?: string,
  /** Текст сообщения или компонент */
  message?: string | React.Component,
  /** Текст кнопки */
  buttonLabel?: string,
  /** Обработчик нажатия кнопки */
  onClick?: (e: Event) => void,
};

const Result = (props: TProps) => {
  return (
    <Wrapper>
      <Topic>{props.topic ? props.topic : ''}</Topic>
      {props.message && <Message>{props.message}</Message>}
      {props.onClick && (
        <Controls>
          <Button label={props.buttonLabel ? props.buttonLabel : 'Попробовать еще раз'} onClick={props.onClick} />
        </Controls>
      )}
    </Wrapper>
  );
};

export default React.memo(Result);
