import React from 'react';
import { MobileButtonBox, MobileText } from './styled';
import SBPButton from '../../Components/Controls/SBPButton';

const MobileView = ({ onCheckout }) => {
  return (
    <>
      <MobileText>Нажмите «Оплатить СБП» для перехода в приложение своего банка</MobileText>
      <MobileButtonBox>
        <SBPButton big onClick={onCheckout} />
      </MobileButtonBox>
    </>
  );
};

export default MobileView;
