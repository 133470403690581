import React, { memo } from 'react';
import Bind from './Bind';
import SecureSign from '../../../../../Components/Blocks/SecureSign';
import { CardBindWrapper, BindCodeBox } from '../styled';
import BindCode from '../../../../../Components/Blocks/BindCode';

const CardBind = (props) => {
  const { values, errors, onCardBind, remainingQuantity, isCardValid } = props;

  const disabled = !isCardValid || Boolean(errors.msisdn);

  return (
    <CardBindWrapper>
      <Bind value={values.isCardBind} disabled={disabled} onClick={onCardBind} bindText="Сохранить карту" />
      {values.isCardBind && (
        <BindCodeBox>
          <BindCode
            msisdn={values.msisdn}
            isBind={values.isCardBind}
            fieldName="acceptCode"
            remainingQuantity={remainingQuantity}
            alternate
          />
        </BindCodeBox>
      )}
      <SecureSign />
    </CardBindWrapper>
  );
};

export default memo(CardBind);
