/**
 * 'Select's Styles for 'ReactSelect'
 *
 * @flow
 */
export const MainStyle = {
  position: 'relative',
  outline: 'none',
  borderRadius: '12px',
  width: '100%',
  height: '60px',
  verticalAlign: 'top',
  boxShadow: 'none',
  background: '#f2f2f2',
};

export const MenuContainerStyle = {
  marginTop: '4px',
  padding: '8px',
  outline: 'none',
  border: 'none',
  borderRadius: '12px',
  maxHeight: '520px',
  background: '#f2f2f2',
  zIndex: '10',
};

export const MenuStyle = {
  height: 'auto',
  maxHeight: '500px',
};
