import React from 'react';
import { FieldDatePicker } from '../../Controls/DatePicker';

import { Field } from './styled';

const DateField = () => {
  return (
    <Field order={1}>
      <FieldDatePicker label={'Дата первого пополнения'} name={'firstDateUtc'} required />
    </Field>
  );
};

export default DateField;
