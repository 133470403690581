/**
 * 'Title's Components
 *
 * @flow
 */
import styled from 'styled-components';

import { breakpoints, fonts, colors } from '../../../Constants/css';

export const Header = styled.h1`
  text-align: left;
  font-family: ${fonts.main};
  font-size: 24px;
  font-weight: bold;
  margin: 0px;
  text-transform: uppercase;
  line-height: 22px;

  color: ${colors.text.main};

  @media (min-width: ${breakpoints.tablet}) {
    font-size: 28px;
    line-height: 24px;
  }
`;
