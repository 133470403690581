/**
 * 'SecureBadges' Component
 *
 * @flow
 */
import React from 'react';
import { images } from '../../../Constants/css';
import { Wrapper } from './styled';
import { withMobile } from '../../HOC';

const SecureBadges = (props) => {
  const { isMobile } = props;

  return (
    <Wrapper>
      <img src={images.pciSecure} alt={'PCI secure'} width={isMobile ? 64 : 46} />
      <img src={images.mirSecure} alt={'MIR secure'} width={isMobile ? 66 : 48} />
      <img src={images.visaSecure} alt={'VISA secure'} width={isMobile ? 53 : 38} />
      <img src={images.masterCardSecure} alt={'MasterCard secure'} width={isMobile ? 61 : 44} />
      <img src={images.mirCard} alt={'MIR'} width={isMobile ? 52 : 32} />
      <img src={images.visaCard} alt={'VISA'} width={isMobile ? 48 : 32} />
      <img src={images.masterCard} alt={'MasterCard'} width={isMobile ? 36 : 32} />
    </Wrapper>
  );
};

export default withMobile(SecureBadges);
