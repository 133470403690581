import React from 'react';
import { compose } from 'recompose';

import Succeed from '../../../../Components/Blocks/Succeed';
import { withCloseInFrame, withErrorsHandle, withMobile } from '../../../../Components/HOC';

class SucceedWithSubscriberAccount extends React.PureComponent {
  render() {
    const { amount, isMobile, topic } = this.props;

    return (
      <Succeed
        {...{
          topic,
          message: `Лицевой счёт пополнен ${isMobile ? `\n` : ''} на сумму ${amount} ₽`,
          isSubmitButtonDisabled: true,
          isSubmitButtonVisible: false,
        }}
      />
    );
  }
}

export default compose(withMobile, withErrorsHandle, withCloseInFrame)(SucceedWithSubscriberAccount);
