import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  margin-top: 4px;
  padding: 8px 0;

  > img {
    margin-right: 12px;
  }

  > span {
    font-size: 12px;
    line-height: 16px;
    color: #7b7b7b;
  }
`;
