import { Form } from 'formik';
import React from 'react';

import CardFields from '../../../../Components/Blocks/CardFields';
import { Content, GrayBlock, Wrapper } from '../../../../Components/Blocks/Common';
import { Wrapper as DetailWrapper } from '../../../../Components/Blocks/DetailsPanel';
import { FormContainer, Row } from '../../../../Components/Blocks/Form';
import Panel from '../../../../Components/Blocks/Panel';
import SavePayButton from '../../../../Components/Blocks/SavePayButton';
import SecureBadges from '../../../../Components/Blocks/SecureBadges';
import SecureSign from '../../../../Components/Blocks/SecureSign';
import Button from '../../../../Components/Controls/Button';
import ReceiptElectron from '../../../../Components/Controls/ReceiptElectron';
import ErrorPanel from '../../../../Components/ErrorPanel';
import { b2bClients } from '../../../../Constants/clientTypes';
import { BlockTitle, CheckCardMessage, CheckCardSum, BindCodeBox } from './styled';
import WithLoop from './WithLoop';
import Oferta from '../../../../Components/Blocks/Oferta';
import BindCode from '../../../../Components/Blocks/BindCode';

const BindCardForm = (props) => {
  const { clientType = '', values, isAlien, buttonLabel } = props;
  const b2bClient = b2bClients.includes(clientType.toLowerCase());

  const { electronReceiptType, electronReceiptEmail, electronReceiptMobile } = values;
  const b2bValid = b2bClient
    ? electronReceiptType === 'email'
      ? !!electronReceiptEmail
      : !!electronReceiptMobile
    : true;

  const isDisabledButton =
    !props.isValid ||
    (!props.values.auth && props.values.isCardBind && props.remainingQuantityConfirm === 0) ||
    !b2bValid;

  return (
    <Wrapper>
      <Form>
        {props && props.commonError && (
          <div style={{ margin: '0px 0px 25px 0px' }}>
            <ErrorPanel text={props.commonError} />
          </div>
        )}
        <Content>
          <Panel width="351px">
            <FormContainer>
              <BlockTitle style={{ marginTop: 0 }}>Реквизиты новой карты</BlockTitle>
              <GrayBlock>
                <CardFields setFieldValue={props.setFieldValue} />

                {isAlien && (
                  <CheckCardMessage>
                    Чтобы проверить карту, мы спишем с нее <CheckCardSum>1 ₽</CheckCardSum> и вернем обратно.
                  </CheckCardMessage>
                )}
                {props.values.isCardBind && (
                  <BindCodeBox>
                    <BindCode
                      msisdn={props.values.msisdn}
                      isBind={props.values.isCardBind}
                      fieldName="acceptCodeConfirm"
                      remainingQuantity={props.remainingQuantityConfirm}
                      alternate
                    />
                  </BindCodeBox>
                )}
                <SecureSign />
              </GrayBlock>

              {props.values.withLoop && <WithLoop remainingQuantity={props.remainingQuantity} />}

              {isAlien && (
                <>
                  <BlockTitle>Электронный чек</BlockTitle>
                  <Row>
                    <ReceiptElectron
                      type={props.values.electronReceiptType}
                      emailInputName={'electronReceiptEmail'}
                      mobileInputName={'electronReceiptMobile'}
                      receiptTypeRadioName={'electronReceiptType'}
                      hideButton={isAlien}
                    />
                  </Row>
                </>
              )}
            </FormContainer>
          </Panel>

          <Panel alternate>
            <DetailWrapper>
              <SavePayButton>
                <Button
                  label={buttonLabel}
                  type={'submit'}
                  disabled={isDisabledButton}
                  big={props.isMobile}
                  alternate
                />
              </SavePayButton>
              <Oferta buttonLabel={buttonLabel} />
              <SecureBadges />
            </DetailWrapper>
          </Panel>
        </Content>
      </Form>
    </Wrapper>
  );
};

export default React.memo(BindCardForm);
