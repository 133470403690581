import React from 'react';

import AutopaymentFields from '../../../../../../Components/Blocks/AutopaymentFields';
import ErrorPanel from '../../../../../../Components/ErrorPanel';
import { Wrapper } from './styled';
import AddAutopaymentWrapper from '../../../../../../Components/Blocks/AddAutopaymentWrapper';

const AutopaymentForm = (props) => {
  const { message, categoriesList, typesList, values, onCategoryChanged, subscriptionFee, topic } = props;

  const isSubmitButtonDisabled = !props.isValid || !values.type || !values.category;

  return (
    <Wrapper>
      {props.commonError && (
        <div style={{ margin: '0px 0px 25px 0px' }}>
          <ErrorPanel text={props.commonError} />
        </div>
      )}
      <AddAutopaymentWrapper
        {...{
          topic,
          message,
          isSubmitButtonDisabled: isSubmitButtonDisabled,
          isSubmitButtonVisible: true,
          submitButtonLabel: 'Подключить автоплатеж',
          onSubmit: props.handleSubmit,
        }}
      >
        <AutopaymentFields
          categoriesList={categoriesList}
          typesList={typesList}
          values={values}
          onCategoryChanged={onCategoryChanged}
          subscriptionFee={subscriptionFee}
        />
      </AddAutopaymentWrapper>
    </Wrapper>
  );
};

export default React.memo(AutopaymentForm);
