import styled from 'styled-components';
import { breakpoints, colors } from '../../../Constants/css';

export const Wrapper = styled.div`
  display: flex;

  flex: 1;
  flex-basis: auto; //Fix IE11
  flex-direction: column;

  @media (min-width: ${breakpoints.tablet}) {
    padding: 24px;
    background-color: ${colors.common.grey};
    border-radius: 32px;
  }
`;

export const Topic = styled.div`
  padding: 8px 0;
  font-size: 22px;
  font-weight: normal;

  color: #000000;
`;

export const Note = styled.div`
  font-size: 12px;

  color: ${colors.common.grey1};

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 11px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 16px;
  row-gap: 12px;

  flex-basis: auto; //Fix IE11
  flex-direction: column;
`;

export const LinkBox = styled.div`
  margin: 19px 0 0 0;
`;

export const TotalTopicBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-weight: 500;
  font-size: 15px;

  @media (min-width: ${breakpoints.tablet}) {
    align-items: end;
  }
`;

export const TotalTopicAmount = styled.span`
  font-weight: bold;
`;
