import React from 'react';
import FieldTextInput from '../../../../../Components/Controls/TextInput/FieldTextInput';

import { amountInputRef } from './refs';

const PaymentInfo = (props) => {
  const { setFieldValue, isAmountDisabled, isSubscriberAccountDisabled } = props;

  const onBlurHandler = (e) => {
    const { value } = e.target;
    if (value[value.length - 1] === '.') {
      const newValue = parseInt(value.toString().split('').slice(0, -1).join(''));
      setFieldValue('amount', newValue, false);
    }
  };
  return (
    <>
      <FieldTextInput
        name={'subscriberAccount'}
        label={'Номер лицевого счета'}
        maskType={'subscriberAccount'}
        maxLength={13}
        required
        disabled={isSubscriberAccountDisabled}
      />
      <FieldTextInput
        type={'tel'}
        name={'amount'}
        maskType={'decimal'}
        label={'Сумма от 1 до 15000 ₽'}
        required
        maxLength={8}
        ref={amountInputRef}
        onBlur={onBlurHandler}
        disabled={isAmountDisabled}
      />
    </>
  );
};

export default PaymentInfo;
