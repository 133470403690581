import styled, { css } from 'styled-components';
import { breakpoints } from '../../../Constants/css';

const desktopWidth = css`
  @media (min-width: ${breakpoints.mobile}) {
    max-width: 250px;
  }
`;

export const Row = styled.div`
  display: flex;

  align-items: center;
`;

export const Selectors = styled.div`
  display: flex;
  flex: 1;
  flex-basis: auto; //Fix IE11

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
  }
`;

export const Field = styled.div`
  width: 100%;

  @media (min-width: ${breakpoints.tablet}) {
    width: calc(50% - 8px);
    order: ${(props) => props.order};
  }
`;

export const Condition = styled.div`
  ${desktopWidth}
  flex: 1;
  flex-basis: auto; //Fix IE11
`;

export const Parameters = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 16px;

  @media (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
  }
`;
