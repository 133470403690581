import React from 'react';
import Result from '../../Components/Blocks/Result';
import Request from '../../Components/Blocks/Request';

const SBPResult = (props) => {
  const { onClose, isPollingEnded, isPaymentSucceed, isBindConfirmed } = props;

  let topic;
  let message;

  if (isPollingEnded) {
    topic = 'Ваш запрос принят';
    message = 'Результат операции будет отправлен в SMS';
  }

  if (isPaymentSucceed) {
    topic = 'Платёж успешно проведён';
    message = (
      <div>
        <Request style={{ marginBottom: '10px' }} text="Ожидайте результат привязки СБП" />
      </div>
    );
  }

  if (isBindConfirmed) {
    topic = 'Платёж успешно проведён';
    message = 'Привязка СБП прошла успешно';
  }

  return <Result topic={topic} message={message} buttonLabel="Закрыть" onClick={onClose} />;
};

export default SBPResult;
