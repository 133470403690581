import React from 'react';

import Switcher from '../../../../../Components/Controls/Switcher';
import { BindBox, BindText } from '../styled';

const Bind = (props) => {
  const { onClick, bindText, disabled, value } = props;

  return (
    <>
      <BindBox>
        <BindText>{bindText}</BindText>
        <Switcher onClick={onClick} on={value} disabled={disabled} />
      </BindBox>
    </>
  );
};

export default React.memo(Bind);
