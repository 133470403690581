import yup from 'yup';
const amountSchema = yup
  .number()
  .test('amount-test', 'Некорректная сумма', (amount = 0) => amount >= 100 && amount <= 3000);
const firstDateUtcSchema = yup.date().required('Обязательное поле');

export const schemas = {
  amountSchema,
  firstDateUtcSchema,
};
