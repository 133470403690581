import SubscriberService from '../../../../../../Services/SubscriberService';
import { CriticalError } from '../../../../../../Constants/errors';
import { setSubscriberId, setAcceptCodeId } from './bindCard';
import { compose } from 'recompose';
import withCommonRequestProcess from '../../../../../../Helpers/withCommonRequestProcess';

const acceptCode = async (props) => {
  if (!props.values.isCardBind) {
    const { msisdn } = props;

    const { subscriberId, isBlocked } = await SubscriberService.getOrCreateSubscriber({
      msisdn,
    });

    if (isBlocked) {
      throw new CriticalError({
        commonErrors: [`Номер ${msisdn} заблокирован для сохранения карты`],
      });
    }

    const { acceptCodeId } = await SubscriberService.sendAcceptCode({
      subscriberId,
    });

    setSubscriberId(subscriberId);
    setAcceptCodeId(acceptCodeId);
  }

  props.setRemainingQuantity('');
  props.setValues({
    acceptCode: '',
    isCardBind: !props.values.isCardBind,
  });
  props.setTouched({
    acceptCode: false,
  });
};

const adapterCommonRequestProcess = (func) => {
  return (props) => {
    const { setErrors, fetching, setCommonError, onBindCardFail: onError } = props;
    func(
      {
        setErrors,
        fetching,
        setCommonError,
        onError,
      },
      props
    );
  };
};

export default compose(adapterCommonRequestProcess, withCommonRequestProcess)(acceptCode);
