import React from 'react';
import { compose } from 'recompose';

import { withErrorsHandle, withFrame, withQueryParams, withThreeDS } from '../../Components/HOC';

import Failed from '../../Components/Blocks/Failed';
import Result from '../_shared/Result';
import Form from './Form';
import { convertKopecksToRublesAsDecimal } from '../../Helpers/Currency';

class SubscriberAccountPaymentSwitcher extends React.Component {
  retry = () => {
    this.props.clearError();
  };

  onError = (error) => {
    this.props.handleError(error);
  };

  onThreeDSSuccess = async (threeDSData) => {
    await this.props.threeDS.redirect(threeDSData);
  };

  onSBPDeepLink = (deepLink, values) => {
    this.props.history.push({
      pathname: '/sbpdeeplink',
      state: { deepLink, values },
    });
  };

  onAreqSucceed = (paymentid) => {
    this.props.history.push(`/subscriberAccountPayment?paymentid=${paymentid}`);
  };

  onFrameSuccess = async (threeDSData) => await this.props.handlePostFrame(threeDSData);

  render() {
    const { criticalError, queryParams } = this.props;
    if (criticalError) {
      return (
        <Failed
          topic={'Не удалось пополнить баланс'}
          message={criticalError}
          onClick={this.retry}
          buttonLabel={'Попробовать еще раз'}
        />
      );
    }

    if (!criticalError && !queryParams.paymentid) {
      return (
        <Form
          subscriberAccount={queryParams.subscriberAccount}
          amount={convertKopecksToRublesAsDecimal(parseInt(this.props.queryParams.amount, 10))}
          email={this.props.queryParams.email}
          onError={this.onError}
          onThreeDSSuccess={this.onThreeDSSuccess}
          onAreqSucceed={this.onAreqSucceed}
          onSBPDeepLink={this.onSBPDeepLink}
          onFrameSuccess={this.onFrameSuccess}
          mobile={queryParams.mobile}
          branch={queryParams.branchId}
        />
      );
    }

    if (queryParams.paymentid) {
      return (
        <Result
          successTopic="Баланс пополнен"
          failedTopic={'Не удалось пополнить баланс'}
          failedMessage="Пожалуйста, попробуйте ещё раз"
          paymentId={queryParams.paymentid}
          paymentType={'topup'}
          isBindCard={false}
          isSubscriberAccountPayment={true}
        />
      );
    }
  }
}

export default compose(withQueryParams, withThreeDS, withErrorsHandle, withFrame)(SubscriberAccountPaymentSwitcher);
