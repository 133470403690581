/**
 * 'Request' Control Component
 *
 * @flow
 */
import React from 'react';

import { Wrapper, Label } from './styled';
import { withMobile } from '../../HOC';
import Animation from '../Animation';

const Request = (props) => {
  const { isMobile, text } = props;
  const animationName = isMobile ? 'smallLoader' : 'bigLoader';
  const size = isMobile ? 24 : 32;

  return (
    <Wrapper data-testid={'block-request'}>
      <Label data-testid={'block-request-label'}>{text ? text : null}</Label>
      <Animation name={animationName} style={{ width: size, height: size }} />
    </Wrapper>
  );
};

export default withMobile(Request);
