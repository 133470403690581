/**
 * 'Select', Control Component
 *
 * @flow
 */
import React, { useState, useRef } from 'react';
import ReactSelect from 'react-select';
import 'react-select/dist/react-select.css';
import './reset.css';

import { Arrow, SelectWrapper, SelectedValue, Label, AdditionalInfo } from './styled';
import { MainStyle, MenuContainerStyle, MenuStyle } from './reactSelectStyles';

import Option from './Option';

export type TOption = {
  label: string,
  value: string,
};

type TProps = {
  /** Значение по умолчанию */
  value: string,
  /** Значение списка в виде
   * [{label: 'Display Label', value: 'option value'}, ...]
   **/
  options: TOption[],
  /** Callback при изменении значения  */
  onChange: (option: TOption) => void,
};

export default function Select(props: TProps) {
  const [isFocused, setIsFocused] = useState(false);
  const selectRef = useRef(null);

  const disabled = Boolean(props.disabled);

  const handleActivateSelect = (...args) => {
    if (disabled) return;

    selectRef.current.input.focus();
    selectRef.current.setState({ isOpen: true });
    setIsFocused(true);
    props.onFocus && props.onFocus(...args);
  };

  const handleBlurSelect = (...args) => {
    setIsFocused(false);
    props.onBlur && props.onBlur(...args);
  };

  return (
    <>
      <SelectWrapper disabled={disabled} onClick={handleActivateSelect}>
        {props.label && <Label selected={!!props.value || isFocused}>{props.label}</Label>}
        <ReactSelect
          ref={selectRef}
          style={MainStyle}
          menuContainerStyle={MenuContainerStyle}
          menuStyle={MenuStyle}
          valueRenderer={(option) => (
            <SelectedValue hasLabel={Boolean(props.label)} disabled={disabled}>
              {option.label}
            </SelectedValue>
          )}
          optionComponent={Option}
          arrowRenderer={(p) => <Arrow {...p} />}
          clearable={false}
          placeholder={null}
          disabled={disabled}
          searchable={Boolean(props.searchable)}
          noResultsText="Нет данных"
          {...props}
          onBlur={handleBlurSelect}
        />
      </SelectWrapper>
      {props.additionalInfo ? <AdditionalInfo>{props.additionalInfo}</AdditionalInfo> : null}
    </>
  );
}
