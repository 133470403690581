/**
 * 'Succeeded', 'Payment's View
 *
 * @flow
 */
import * as React from 'react';
import { compose } from 'recompose';

import Button from '../../Controls/Button';
import { withCloseInFrame, withMobile } from '../../HOC';
import Panel from '../Panel';
import { Wrapper, Buttons } from './styled';

type TProps = {
  isSubmitButtonDisabled?: boolean,
  isSubmitButtonVisible?: boolean,
  submitButtonLabel?: string,
  message: string,
  onSubmit?: Function,
  onClose: Function,
  children: any /** React.ReactNode */,
  isMobile: boolean,
};

const AddAutopaymentWrapper = (props: TProps) => {
  return (
    <Wrapper data-testid={'block-result'}>
      <Panel>{props.children ? props.children : null}</Panel>

      <Buttons>
        {props.isSubmitButtonVisible && (
          <>
            <Button
              disabled={props.isSubmitButtonDisabled}
              label={props.submitButtonLabel || ''}
              onClick={props.onSubmit}
              big
              data-testid={'block-result-button-submit'}
              alternate
            />
          </>
        )}
        <Button
          disabled={false}
          label={'Закрыть'}
          onClick={props.onClose}
          ghost
          big
          data-testid={'block-result-button-close'}
        />
      </Buttons>
    </Wrapper>
  );
};
export default compose(withMobile, withCloseInFrame)(React.memo(AddAutopaymentWrapper));
