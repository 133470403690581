import styled from 'styled-components';
import { breakpoints } from '../../../../Constants/css';

export const CardInfoButtonBox = styled.div`
  margin-top: 24px;
  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 12px;
  }
`;

export const Condition = styled.div`
  flex: 1;
  flex-basis: auto; //Fix IE11
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;
