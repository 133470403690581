import React from 'react';
import Lottie from 'lottie-react';
import { animations } from './constants';

const Animation = (props) => {
  const { name, style } = props;

  const animationData = animations[name];

  return <Lottie animationData={animationData} style={style} />;
};

export default Animation;
