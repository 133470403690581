import React, { memo } from 'react';
import Bind from './Bind';
import BindCode from '../../../../../Components/Blocks/BindCode';

const SBPBind = (props) => {
  const { values, errors, onSbpBind, remainingQuantity } = props;

  const disabled = Boolean(errors.msisdn);

  return (
    <>
      <Bind
        value={values.isSbpBind}
        disabled={disabled}
        onClick={onSbpBind}
        bindText={`Сохранить СБП для быстрой \n оплаты`}
      />
      <BindCode
        msisdn={values.msisdn}
        isBind={values.isSbpBind}
        fieldName="sbpAcceptCode"
        remainingQuantity={remainingQuantity}
      />
    </>
  );
};

export default memo(SBPBind);
