import * as React from 'react';
import { withMobile } from '../../../../../Components/HOC';
import { Wrapper, Topic, Note, TotalTopicAmount, TotalTopicBox } from '../../../../../Components/Blocks/DetailsPanel';
import Panel from '.././../../../../Components/Blocks/Panel';
import CardInfo from './CardInfo';

const TotalPanel = (props) => {
  const { errors, values, isValid } = props;
  return (
    <Panel alternate>
      <Wrapper>
        <Topic>
          <TotalTopicBox>
            <div>
              <div>Итого к зачислению</div>
              <Note>Без комиссии</Note>
            </div>
            <TotalTopicAmount>{`${!errors.amount ? values.amount : '0'} ₽`}</TotalTopicAmount>
          </TotalTopicBox>
        </Topic>
        <CardInfo isValid={isValid} />
      </Wrapper>
    </Panel>
  );
};

export default withMobile(TotalPanel);
