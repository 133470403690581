import React from 'react';
import { SBPInfoDescription } from '../styled';

const SBPInfo = () => {
  return (
    <SBPInfoDescription>
      Система быстрых платежей – сервис, позволяющий клиентам банков-участников СБП совершать переводы по номеру
      мобильного телефона, а также оплачивать по QR-коду
    </SBPInfoDescription>
  );
};

export default SBPInfo;
