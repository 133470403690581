import React from 'react';
import moment from 'moment';

import FieldSelect from '../../Controls/Select/FieldSelect';
import { FieldTextInput } from '../../Controls/TextInput';
import { CategoryAdditionalInfoText, CATEGORY_PERIODIC, CATEGORY_SUBSCRIPTIONFEE } from './constants';
import { BlockTitle, LightBlueBlock } from '../Common';
import DateField from './DateField';
import { amountInputRef } from './refs';
import { Field, Parameters } from './styled';

const AutopaymentFields = (props) => {
  const { categoriesList, typesList, onCategoryChanged, values, subscriptionFee } = props;
  const { category, amount } = values;

  const isSubscriptionFee = category === CATEGORY_SUBSCRIPTIONFEE;
  const isPeriodic = category === CATEGORY_PERIODIC;

  const amountText = `Абонентская плата — ${amount} ₽/мес`;
  const subscriptionFeeDateText =
    isSubscriptionFee && subscriptionFee.nextDate
      ? `Дата списания — ${moment(subscriptionFee.nextDate, 'DD.MM.YYYY').format('D MMMM')}`
      : null;

  const categoryAdditionalInfo = category ? CategoryAdditionalInfoText[category] : null;
  const amountAdditionalInfo = amount ? amountText : null;

  const amountLabel = isSubscriptionFee ? `Сумма` : 'Сумма от 100 до 3000 ₽';

  return (
    <>
      <BlockTitle>Параметры автоплатежа</BlockTitle>
      <Parameters>
        <Field>
          <FieldSelect
            label="Категория автоплатежа"
            name={'category'}
            options={categoriesList}
            onChange={onCategoryChanged}
            additionalInfo={categoryAdditionalInfo}
          />
        </Field>

        {isSubscriptionFee ? (
          <LightBlueBlock style={{ marginTop: 0 }}>
            <div>{amountText}</div>
            <div>{subscriptionFeeDateText}</div>
          </LightBlueBlock>
        ) : (
          <>
            <Field>
              <FieldSelect label="Тип автоплатежа" name={'type'} options={typesList} />
            </Field>
            <Field order={2}>
              <FieldTextInput
                type={'tel'}
                name={'amount'}
                maskType={isSubscriptionFee ? 'decimal' : 'amountInteger'}
                label={amountLabel}
                required
                maxLength={isSubscriptionFee ? 7 : 4}
                hidden={isSubscriptionFee}
                ref={amountInputRef}
                additionalInfo={amountAdditionalInfo}
              />
            </Field>
          </>
        )}

        {isPeriodic ? <DateField /> : null}
      </Parameters>
    </>
  );
};

export default React.memo(AutopaymentFields);
