/**
 * 'Autopayment's Styled Components
 *
 * @flow
 */
import styled from 'styled-components';
import { fonts } from '../../../../../../Constants/css';

export const Wrapper = styled.div`
  display: flex;

  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  column-gap: 8px;

  align-items: center;
`;

export const Title = styled.div`
  font-family: ${fonts.alternate};
  font-size: 15px;
`;

export const Separator = styled.div`
  flex: 1;
  flex-basis: auto; //Fix IE11
`;
