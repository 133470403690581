import React from 'react';
import { compose } from 'recompose';
import Failed from '../../../../Components/Blocks/Failed';
import Succeed from '../../../../Components/Blocks/Succeed';
import { withCloseInFrame, withErrorsHandle } from '../../../../Components/HOC';
import AutopaymentForm from './Form';

class SucceedWithAutopayment extends React.Component {
  state = {
    isSuccess: false,
    autopaymentAmount: null,
    isAutopayment: false,
  };
  onSuccess = (autopaymentAmount) => {
    this.setState({ isSuccess: true, autopaymentAmount });
  };
  onSubmit = () => {
    this.setState({ isAutopayment: true });
  };
  render() {
    const { message, msisdn, paymentId, amount, criticalError, handleError, onClose, paymentType, topic } = this.props;
    const { isSuccess, autopaymentAmount, isAutopayment } = this.state;
    const { onSuccess, onSubmit } = this;

    return (
      <>
        {criticalError && (
          <Failed
            topic={'Не удалось подключить автоплатёж'}
            message={criticalError}
            onClick={onClose}
            buttonLabel={'Закрыть'}
          />
        )}
        {!criticalError && !isSuccess && !isAutopayment && (
          <Succeed
            {...{
              topic,
              message,
              isSubmitButtonDisabled: false,
              isSubmitButtonVisible: true,
              submitButtonLabel: 'Подключить автоплатеж',
              onSubmit,
            }}
          />
        )}
        {!criticalError && !isSuccess && isAutopayment && (
          <AutopaymentForm
            {...{
              topic,
              msisdn,
              message,
              paymentId,
              amount,
              onSuccess,
              onFail: handleError,
              paymentType,
            }}
          />
        )}
        {!criticalError && isSuccess && (
          <Succeed
            {...{
              topic: 'Заявка на создание автоплатежа принята',
              message: `Абонентская плата — ${autopaymentAmount} ₽`,
            }}
          />
        )}
      </>
    );
  }
}

export default compose(withErrorsHandle, withCloseInFrame)(SucceedWithAutopayment);
