/**
 * 'Layout', Block Component
 *
 * @flow
 */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import Title from '../../Controls/Title';
import { withCloseInFrame, withQueryParams } from '../../HOC';
import { CloseIcon, Container, Header, Wrapper } from './styled';
import { convertStringToBoolean } from '../../../Helpers/Currency';
import { images } from '../../../Constants/css';

type TProps = {
  /** Заголовок */
  title: string,
  /** Вложенные элементы */
  children: any /** React.ReactNode */,
  /** Обработчик закрытия окна */
  onClose: Function,
  /** Query параметры */
  queryParams: Object,
};

const Layout = (props: TProps) => {
  const isCloseable = convertStringToBoolean(props.queryParams.closeable);
  return (
    <Wrapper>
      <Container>
        <Header titleCentered={props.titleCentered}>
          <Title text={props.title} alignLeft={isCloseable} />
          {isCloseable ? <CloseIcon src={images.cross} onClick={props.onClose} alt="" /> : null}
        </Header>
        {props.children}
      </Container>
    </Wrapper>
  );
};

export default compose(withRouter, withQueryParams, withCloseInFrame)(React.memo(Layout));
