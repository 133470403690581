//
// @flow
//
import { getCard } from '../Api/Cards';
import { NotFoundError, ValidationError } from '../Constants/errors';
import { normalizePan, sliceExpirationDate } from '../Helpers/Normalize';

import type { TGetCardRaw, TGetCardResponse } from '../Constants/types';

export default class CardService {
  static async getCard({ pan = '', expirationDate = '' }: TGetCardRaw): Promise<TGetCardResponse> {
    const { expirationMonth, expirationYear } = sliceExpirationDate(expirationDate);

    return getCard({
      pan: normalizePan(pan),
      expirationMonth,
      expirationYear,
    });
  }

  static async getCardIdForBind({ pan = '', expirationDate = '' }: TGetCardRaw): Promise<{ cardId: string }> {
    const { expirationMonth, expirationYear } = sliceExpirationDate(expirationDate);

    try {
      const { cardId, isBlocked, isBound, maskedMsisdn } = await getCard({
        pan: normalizePan(pan),
        expirationMonth,
        expirationYear,
      });

      if (isBlocked || isBound) {
        let message = '';

        if (isBlocked) {
          message = 'Данная карта не может быть привязана';
        }
        if (isBound) {
          message = `Данная карта была привязана ранее к номеру телефона +79***${maskedMsisdn}. 
          Подключайте автоплатежи и управляйте картой из Личного кабинета номера +79***${maskedMsisdn} https://t2.ru/lk или воспользуйтесь другой банковской картой.`;
        }

        throw new ValidationError({
          commonErrors: [message],
        });
      }

      return { cardId };
    } catch (error) {
      if (error instanceof NotFoundError) {
        // 404 ошибку не обрабатываем
        // по бизнес кейсу карта будет создана при оплате
        return { cardId: '' };
      } else {
        throw error;
      }
    }
  }
}
