//@flow

import React, { Component } from 'react';
import { compose } from 'recompose';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import Fetching from './Components/Context/Fetching';
import IsMobile from './Components/Context/IsMobile';

import { withCssReset, withT2Fonts } from './Components/HOC';

import BindCard from './Pages/BindCard';
import B2bBindCard from './Pages/B2bBindCard';
import AddAutopayment from './Pages/AddAutopayment';
import SinglePayment3DS from './Pages/SinglePayment3DS';
import SinglePayment3DSResult from './Pages/SinglePayment3DSResult';
import AutopaymentsConfirm from './Pages/AutopaymentsConfirm';
import Autopayment from './Pages/Autopayment';
import TopUp from './Pages/TopUp';
import SubscriberAccountPayment from './Pages/SubscriberAccountPayment';
import SBPDeepLink from './Pages/SBPDeepLink';
import SelfRegistration from './Pages/SelfRegistration';
import SberPay from './Pages/SberPay';

class App extends Component<*, *> {
  render() {
    return (
      <Fetching>
        <Router>
          <IsMobile>
            <Switch>
              <Route path={'/'} render={(routeProps) => <TopUp {...routeProps} />} exact />
              <Route path={'/payment'} render={(routeProps) => <TopUp {...routeProps} />} exact />
              <Route
                path={'/subscriberAccountPayment'}
                render={(routeProps) => <SubscriberAccountPayment {...routeProps} />}
                exact
              />
              <Route path={'/bindcard'} render={(routeProps) => <BindCard {...routeProps} />} exact />
              <Route path={'/bindcardalien'} render={(routeProps) => <BindCard {...routeProps} />} exact />
              <Route path={'/b2bbindcard'} render={(routeProps) => <B2bBindCard {...routeProps} />} exact />
              <Route path={'/addautopayment'} render={(routeProps) => <AddAutopayment {...routeProps} />} exact />
              <Route path={'/singlepayment3ds'} render={(routeProps) => <SinglePayment3DS {...routeProps} />} exact />
              <Route
                path={'/singlepayment3dsresult'}
                render={(routeProps) => <SinglePayment3DSResult {...routeProps} />}
                exact
              />
              <Route
                path={'/autopayments/:id/confirm/:code'}
                render={(routeProps) => <AutopaymentsConfirm {...routeProps} />}
                exact
              />
              <Route path={'/autopayment'} render={(routeProps) => <Autopayment {...routeProps} />} exact />
              <Route path={'/sbpdeeplink'} render={(routeProps) => <SBPDeepLink {...routeProps} />} exact />
              <Route path={'/selfregistration'} render={(routeProps) => <SelfRegistration {...routeProps} />} exact />
              <Route path={'/sberpay'} render={(routeProps) => <SberPay {...routeProps} />} exact />
            </Switch>
          </IsMobile>
        </Router>
      </Fetching>
    );
  }
}

export default compose(withT2Fonts, withCssReset)(App);
