import styled from 'styled-components';
import { colors } from '../../../Constants/css';

const { border } = colors;

export const ElectronReceipt = styled.div`
  display: flex;
  position: relative;

  flex: 1;
  flex-basis: auto; //Fix IE11
`;

export const ElectronReceiptInput = styled.div`
  flex: 1;
  flex-basis: auto; //Fix IE11
`;

export const ElectronReceiptButton = styled.div`
  display: flex;

  width: 60px;
  height: 60px;

  border-width: 1px;
  border-style: solid;
  border-color: ${border.normal};
  border-radius: 8px;

  justify-content: center;
  align-items: center;

  cursor: pointer;

  background-color: transparent;

  :hover {
    border-color: ${border.hover};
  }
`;
