import { validateYupSchema, yupToFormErrors } from 'formik';
import { compose, lifecycle, withHandlers, withProps, withStateHandlers } from 'recompose';
import yup from 'yup';

import {
  CATEGORY_PERIODIC,
  CATEGORY_SUBSCRIPTIONFEE,
} from '../../../../../Components/Blocks/AutopaymentFields/constants';
import { amountInputRef } from '../../../../../Components/Blocks/AutopaymentFields/refs';
import { withFetching } from '../../../../../Components/HOC';
import withFormik from '../../../../../Components/HOC/withFormikHelper';
import { schemas as commonSchemas } from '../../../../../Helpers/validation';
import pullAutopayment from '../Scenario/pullAutopayment';
import pullSubscriptionFee from '../Scenario/pullSubscriptionFee';
import pushAutopayment from '../Scenario/pushAutopayment';
import { schemas as customSchemas } from './validation.js';
import AutopaymentForm from './View';

const { amountSchema, firstDateUtcSchema, ...schemas } = { ...commonSchemas, ...customSchemas };

export default compose(
  withFetching,
  withStateHandlers(
    {
      types: [],
      categories: [],
      commonError: '',
      subscriptionFee: {},
    },
    {
      setTypes: () => (types) => ({ types }),
      setCategories: () => (categories) => ({ categories }),
      setCommonError: () => (commonError) => ({ commonError }),
      setSubscriptionFee: () => (subscriptionFee) => ({ subscriptionFee }),
    }
  ),
  withProps({
    values: {
      withAutopayment: false,
      category: '' /** type number - billingServiceCategoryId */,
      type: '' /** type number - billingServiceId */,
      amount: '',
      firstDateUtc: undefined,
    },
  }),
  withFormik({
    handleSubmit: pushAutopayment,
    /**
     * Why validate instead of validationSchema
     * @see https://github.com/jaredpalmer/formik/issues/431#issuecomment-377082443
     */
    validate: async (values, props) => {
      let errors = {};

      const subscriptionCategory = values.category === CATEGORY_SUBSCRIPTIONFEE;
      const isPeriodicCategory = values.category === CATEGORY_PERIODIC;

      const finalSchemas = subscriptionCategory
        ? schemas
        : isPeriodicCategory
        ? { ...schemas, amountSchema, firstDateUtcSchema }
        : { ...schemas, amountSchema };
      const validationSchema = yup.object(
        Object.keys(values).reduce((acc, key) => {
          if (finalSchemas[`${key}Schema`]) {
            acc[key] = finalSchemas[`${key}Schema`];
          }

          return acc;
        }, {})
      );

      try {
        await validateYupSchema(values, validationSchema);
      } catch (err) {
        errors = yupToFormErrors(err);
      }

      if (subscriptionCategory) {
        if (Number(values.amount) < props.subscriptionFee.amount) {
          errors.amount = `Некорректная сумма. Должна быть выше или равна ${props.subscriptionFee.amount} руб.`;
        }
      }

      throw errors;
    },
  }),
  withProps((props) => ({
    categoriesList: props.categories.map((category) => ({
      label: category.name,
      value: category.billingServiceCategoryId,
    })),
    typesList: props.types
      .filter((type) => type.billingServiceCategory.id === props.values.category)
      .map((type) => ({
        label: type.name,
        value: type.billingServiceId,
      })),
  })),
  withHandlers({
    onCategoryChanged: (props) => (category) => {
      if (category.value === CATEGORY_SUBSCRIPTIONFEE && !Object.keys(props.subscriptionFee).length) {
        pullSubscriptionFee(props);
      } else {
        props.setFieldValue('category', category.value);
        if (props.values.category === CATEGORY_SUBSCRIPTIONFEE) {
          props.setFieldValue('amount', '');
        }
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      pullAutopayment(this.props);
    },
    componentDidUpdate(prevProps) {
      const { props } = this;

      if (prevProps.values.msisdn !== props.values.msisdn) {
        if (commonSchemas.msisdnSchema.isValidSync(props.values.msisdn)) {
          pullAutopayment(props);
        } else {
          props.setFieldValue('withAutopayment', false);
        }
      }

      if (prevProps.values.category !== this.props.values.category) {
        if (this.props.values.category) {
          const filteredTypesByCategory = this.props.types.filter(
            (type) => type.billingServiceCategory.id === this.props.values.category
          );

          if (filteredTypesByCategory.length) {
            const defaultTypeItem = filteredTypesByCategory.find((type) => type.isDefault);
            const firstInListTypeItem = filteredTypesByCategory[0];

            this.props.setValues({
              ...this.props.values,
              type: defaultTypeItem
                ? defaultTypeItem.billingServiceId
                : firstInListTypeItem
                ? firstInListTypeItem.billingServiceId
                : '',
              amount:
                this.props.values.category === CATEGORY_SUBSCRIPTIONFEE
                  ? this.props.subscriptionFee.amount
                  : this.props.values.amount,
            });

            if (this.props.values.amount) {
              if (amountInputRef.current) {
                amountInputRef.current.activateInput();
              }
            }
          }
        }
      }
    },
  })
)(AutopaymentForm);
