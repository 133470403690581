/**
 * 'Request's Styled Components
 *
 * @flow
 */
import styled from 'styled-components';

import { breakpoints, fonts } from '../../../Constants/css';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const Label = styled.div`
  font-family: ${fonts.main}
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  
  color: #000000;

  @media (min-width: ${breakpoints.tablet}) {
    font-size: 22px;
  }
`;
