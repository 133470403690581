/**
 * 'RadioTabGroup', Control Component
 * @flow
 */
import React from 'react';
import { ButtonContainer } from './styled';
import type { Node } from 'react';

type TProps = {
  checked: string,
  children: Node,
  setChecked: (name: string) => *,
};

const RadioTabGroup = (props: TProps) => {
  const { checked, setChecked, children } = props;
  const childrenArray = React.Children.map(children, (child) => child);
  const checkedChild: any = checked ? childrenArray.find((c) => c.props.name === checked) : childrenArray[0];
  const newChildren: Node[] = childrenArray.map((child) => {
    return React.cloneElement(child, {
      checked: child.props.name === checkedChild.props.name,
      onChange: () => setChecked(child.props.name),
    });
  });

  return (
    <>
      <ButtonContainer>{newChildren}</ButtonContainer>
      {checkedChild.props.children}
    </>
  );
};

export default RadioTabGroup;
