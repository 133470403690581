import styled from 'styled-components';
import { breakpoints } from '../../../Constants/css';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: repeat(2, 32px);
  grid-gap: 8px 24px;
  align-items: center;

  margin-top: 16px;

  @media (min-width: ${breakpoints.tablet}) {
    grid-gap: 4px 8px;
    margin-top: 4px;
  }
`;
